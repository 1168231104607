import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ReleaseFeatureFlags } from '../model/release-feature-flags';

export const GlobaStateDataActions = createActionGroup({
  source: 'Global State Data Actions',
  events: {
    fetchFeatureFlags: emptyProps(),
    fetchFeatureFlagSuccess: props<{ releaseFlagResponse: ReleaseFeatureFlags; }>(),
    fetchFeatureFlagFailure: props<{ errorMsg: string }>(),
  },
});
