import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import {MatCard} from "@angular/material/card";
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReactiveFormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment';
import '@shui/core/sh-page';
import '@shui/core/sh-access-bar';
import '@shui/core/sh-nav-bar';
import '@shui/core/sh-search';
import '@shui/core/sh-user-identifier';
import '@shui/core/sh-icon';
import '@shui/core/sh-input-text';
import '@shui/core/sh-text';
import '@shui/core/sh-button';
import '@shui/core/sh-toggle';
import '@shui/core/styles';
import '@shui/core/sh-wrapper';
import '@shui/core/sh-dropdown';
import '@shui/core/sh-menu-item';
import '@shui/core/sh-pane';
import '@shui/core/sh-divider';
import '@shui/core/sh-tabs';
import '@shui/core/sh-list';
import '@shui/core/sh-card';
import '@shui/core/sh-grid';
import '@shui/core/sh-accordion';
import '@shui/core/sh-switch';
import '@shui/core/sh-table';
import '@shui/core/sh-slider'
import '@shui/core/sh-datepicker';
import '@shui/core/sh-spinner'
import '@shui/core/sh-empty-state';
import '@shui/core/sh-popover';
import '@shui/core/sh-input-number';
import '@shui/core/sh-stepper';
import '@shui/core/sh-radio-button';
import '@shui/core/sh-breadcrumbs';
import '@shui/core/sh-home-screen';
import '@shui/core/sh-modal';
import '@shui/core/sh-progress';
import '@shui/core/sh-split-button';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TestSelectorComponent } from './components/test-selector/test-selector.component';
import { QcReviewComponent } from './components/qc-review/qc-review.component';
import { QcDashboardComponent } from './components/qc-dashboard/qc-dashboard.component';

import {
  IPublicClientApplication,
  PublicClientApplication,
  BrowserCacheLocation,
  LogLevel,
  InteractionType,
} from '@azure/msal-browser';
import {
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalInterceptorConfiguration,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
  MsalBroadcastService,
  MsalService,
  MsalGuard,
  MsalRedirectComponent,
  MsalModule,
  MsalInterceptor,
} from '@azure/msal-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AccessBarComponent } from './components/access-bar/access-bar.component';
import { FleetComparisonComponent } from './components/fleet-comparison/fleet-comparison.component';
import { LJChartComponent } from './components/l-j-chart/l-j-chart.component';
import { FleetPDFComponent } from './components/fleet-pdf/fleet-pdf.component';
import { QcComparisonComponent } from './components/qc-comparison/qc-comparison.component';
import { DecimalForInputPipe } from './pipes/decimal-for-input/decimal-for-input.pipe';
import { AppConfigService } from './services/app-config.service';
import { SseService } from './services/communication/sse.service';
import { HomeComponent } from './components/home/home.component';
import { ActivatedRoute } from '@angular/router';
import { DeviceListService } from './services/device-list.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { SearchSerialNumberComponent } from './components/search-serial-number/search-serial-number.component';
import { Store, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { SharedModule } from './modules/shared/shared.module';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { firstValueFrom } from 'rxjs';
import { HttpHeadersInterceptorModule } from './interceptors/http-headers-interceptor/http-headers-interceptor.module';
import { NetworkService } from './services/communication/network.service';
import { ErrorNotificationComponent } from './modules/error-notification/components/error-notification.component';
import { VideoTutorialComponent } from './components/video-tutorial/video-tutorial.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { globalStateFeature } from './+store/global.reducers';
import { GlobalStateEffects } from './+store/global.effects';
import { GlobaStateDataActions } from './+store/global.actions';


const GRAPH_ENDPOINT = 'Enter_the_Graph_Endpoint_Herev1.0/me';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log('log level:', logLevel, ' message:', message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId, // Application (client) ID from the app registration
      authority:
        'https://login.microsoftonline.com/5dbf1add-202a-4b8d-815b-bf0fb024e033', // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
      redirectUri: `${environment.uiUrl}/home`, // This is your redirect URI,
      postLogoutRedirectUri: `${environment.uiUrl}/welcome`,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      allowNativeBroker: false,
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}


export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(GRAPH_ENDPOINT, ['user.read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [ environment.scope ],
    },
  };
}

// App initializer to acquire access token on app load
export function initializeApp(appConfigService: AppConfigService, sseService: SseService, msalService: MsalService, store: Store): () => Promise<any> {
  return async () => {
    if (appConfigService.appConfig === undefined) {
      appConfigService.startService();
    }
    sseService.getServerSentEvent()
    .catch(error => {
      return Promise.reject(new Error('failed to initialize SSE service: ' + error));
    });

    if (environment.local) {
      store.dispatch(GlobaStateDataActions.fetchFeatureFlags());
      return Promise.resolve();
    } else {
      await firstValueFrom(msalService.initialize());
      return msalService.instance
      .handleRedirectPromise()
      .then((res) => {
        if (!res) {
          return firstValueFrom(
            msalService.acquireTokenSilent({
              scopes: [ environment.scope ],
            })
          )
          .then((authResult) => {
            NetworkService.accessToken = authResult.accessToken;
            store.dispatch(GlobaStateDataActions.fetchFeatureFlags());
          })
          .catch((error) => {
            console.error('Token acquisition failed during initialization:', error);
            return Promise.resolve();
          });
        } else if (res.accessToken) {
          if (res.account !== null) {
            msalService.instance.setActiveAccount(res.account);
          }
          NetworkService.accessToken = res.accessToken;
          store.dispatch(GlobaStateDataActions.fetchFeatureFlags());
          return Promise.resolve();
        } else {
          console.error('Error acquiring token in 2nd catch:', res);
          store.dispatch(GlobaStateDataActions.fetchFeatureFlags());
          return Promise.resolve();
        }        
      })
      .catch((err) => {
        console.error('Error acquiring token in 3rd catch', err);
        return Promise.resolve();
      });      
    }    
  }
}

@NgModule({
  declarations: [
    AppComponent,
    TestSelectorComponent,
    QcReviewComponent,
    QcDashboardComponent,
    AccessBarComponent,
    FleetComparisonComponent,
    LJChartComponent,
    FleetPDFComponent,
    QcComparisonComponent,
    DecimalForInputPipe,
    HomeComponent,
    SearchSerialNumberComponent,
    WelcomeComponent,
    VideoTutorialComponent,
    FeedbackComponent
  ],
  imports: [
    NgxDocViewerModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatIcon,
    AppRoutingModule,
    ReactiveFormsModule,
    MsalModule,
    ErrorNotificationComponent,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: !environment.local,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000",
    }),
    StoreModule.forRoot({}),
    EffectsModule.forRoot(),
    StoreModule.forFeature(globalStateFeature),
    EffectsModule.forFeature([GlobalStateEffects]),
    StoreRouterConnectingModule.forRoot(),
    SharedModule,
    HttpHeadersInterceptorModule,
    MatCard,
  ],
  providers: [
    AppConfigService,
    SseService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfigService, SseService, MsalService, Store],
      multi: true
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor(
    private route: ActivatedRoute,
    private deviceListService: DeviceListService
  ) {
    this.route.queryParams.subscribe(params => {
      if (params['moduleType']) {
        this.deviceListService.selectedModuleType.set({
          moduleType: params['moduleType'],
          materialNumber: params['moduleType']
        })
      }
    })
  }
}
