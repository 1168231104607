import { Injectable } from '@angular/core';
import { QcDataStoreService } from './qc-data-store.service';
import { firstValueFrom } from 'rxjs';
import { QcCommunicationModel, QcReference, QcResult } from '../model/qc.communication.model';
import { QcLJFilter, QcLJSelectedFilter } from '../model/qc-l-j-filter.model';
import { QcLJGraphMetaData } from '../model/QcLJGraphMetaData.model';
import { QcFilter } from '../model/qc-dashboard.model';
import { QcService } from './qc.service';
import { AppConfigService } from './app-config.service';
import { SseService } from './communication/sse.service';
import { NetworkService } from './communication/network.service';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class QcReviewService extends QcService {

  constructor(qcDataStore: QcDataStoreService, appConfigService: AppConfigService, sseService: SseService, networkService: NetworkService) {
    super(appConfigService, sseService, networkService, qcDataStore);
  }

  override async getData(deviceId: string, dateRange: string, lastDay?: boolean): Promise<QcCommunicationModel> {
    let data = await super.getData(deviceId, dateRange, lastDay);
    this.qcDataStore.updateData(data);
    return data;
  }

  async getDefaultFilter(): Promise<QcFilter> {
    let defaultfilter = await firstValueFrom(this.qcDataStore.qcFilter$);
    return defaultfilter!;
  }

  async getQcReviewFilters(): Promise<QcLJSelectedFilter | undefined> {
    const qcReviewFilters = await firstValueFrom(this.qcDataStore.qcReviewFilter$);
    return qcReviewFilters;
  }

  updateFiltersInStore(filters: QcLJSelectedFilter): void {
    this.qcDataStore.updateQcReviewFilter(filters)
  }

  getFilters(data: QcCommunicationModel, assayCode: string): QcLJFilter {
    let testFilteredData = data.data.qcDetails.qcDataList.filter(t => t.assay == assayCode);
    let filter = new QcLJFilter();
    for (let test of testFilteredData) {
      filter.qcLotLevel.add(test.qcLotLevel);
      filter.specimenType.add(test.specType);
      filter.units.add(test.unit);
      for (let result of test.qcResults) {
        filter.calLot.add(result.calLot);
        filter.reagentLot.add(result.rgtLot);
      }
    }
    return filter;
  }

  getFilteredData(data: QcCommunicationModel, filter: QcLJSelectedFilter): QcLJGraphMetaData[] {
    let filteredData: QcLJGraphMetaData[] = [];
    for (let item of data.data.qcDetails.qcDataList) {
      const testMatch = filter.test.name === item.assay;
      const unitsMatch = filter.units === item.unit;
      const qcLotLevelMatch = filter.qcLotLevel.has(item.qcLotLevel);
      const specMatch = filter.specimenType === item.specType;
      if (testMatch && unitsMatch && qcLotLevelMatch && specMatch) {
        let tempFilter: QcLJSelectedFilter = {
          serial: filter.serial,
          test: filter.test,
          units: item.unit,
          qcLotLevel: new Set<string>(),
          reagentLot: '',
          calLot: '',
          latestDate: filter.latestDate,
          earliestDate: filter.earliestDate,
          specimenType: item.specType,
          dateRangeString: '',
          assay: item.assay
        }
        tempFilter.qcLotLevel.add(item.qcLotLevel);
        let filteredItem: QcLJGraphMetaData = {
          qcLevel: item.qcLevel,
          qcLotLevel: item.qcLotLevel,
          qcResults: [],
          sD1Low: item.qcReferences.sd1Low,
          sD1_Up: item.qcReferences.sd1Up,
          sD2Low: item.qcReferences.sd2Low,
          sD2Up: item.qcReferences.sd2Up,
          sD3Low: item.qcReferences.sd3Low,
          sD3Up: item.qcReferences.sd3Up,
          mean: item.qcReferences.mean,
          sd: item.qcReferences.sd,
          specType: item.specType,
          assay: item.assay,
          assayName: item.assayName,
          deviceId: data.data.qcDetails.serialNo,
          ymax: Number.MIN_VALUE,
          ymin: Number.MAX_VALUE,
          unit: item.unit,
          qcResultMax: Number.MIN_VALUE,
          qcResultMin: Number.MAX_VALUE,
          cv: item.qcReferences.cv,
          filter: tempFilter,
          sdCalcCount: 0
        }

        filteredItem.qcResults = item.qcResults.filter(result => {
          const reagentLotMatch = filter.reagentLot === 'View All' || filter.reagentLot === 'None' || filter.reagentLot === result.rgtLot;
          const calLotMatch = filter.calLot === 'View All' || filter.calLot === 'None' || filter.calLot === result.calLot;
          if (reagentLotMatch && calLotMatch) {
            filteredItem.filter.reagentLot = filter.reagentLot === 'View All' || filter.reagentLot === 'None' ? filter.reagentLot : result.rgtLot;
            filteredItem.filter.calLot = filter.calLot === 'View All' || filter.calLot === 'None' ? filter.calLot : result.calLot;
            filteredItem.ymin = Math.min(filteredItem.ymin, result.value, filteredItem.sD3Low - (3 * filteredItem.sd));
            filteredItem.ymax = Math.max(filteredItem.ymax, result.value, filteredItem.sD3Up + (3 * filteredItem.sd));
            filteredItem.qcResultMax = Math.max(filteredItem.qcResultMax, result.value);
            filteredItem.qcResultMin = Math.min(filteredItem.qcResultMin, result.value);
            filteredItem.sdCalcCount++;
            return true;
          }
          return false;
        });

        if (filteredItem.qcResults.length > 0) {
          filteredItem.ymax = Math.max(filteredItem.ymax, filteredItem.sD3Up + filteredItem.sd + filteredItem.sd + filteredItem.sd);
          filteredItem.ymin = Math.min(filteredItem.ymin, filteredItem.sD3Low - filteredItem.sd - filteredItem.sd - filteredItem.sd);
          filteredData.push(filteredItem);
        }
      }
    }
    return filteredData;
  }

  getAssays(filter: QcLJSelectedFilter, data: QcCommunicationModel): Map<string, string> {
    let assays = new Map<string, string>();
    for (let qcData of data.data.qcDetails.qcDataList) {
      for (let result of qcData.qcResults) {
        //Extract the date portion excluding Zone info and time without any conversions, considering result time as it is from API/DB
        const resultDate = moment(result.time.split('T')[0], 'YYYY-MM-DD', true).toDate();
        const dateMatch = filter.earliestDate <= resultDate && filter.latestDate >= resultDate;
        if (dateMatch) {
          assays.set(qcData.assay, qcData.assayName);
        }
      }
    }
    return assays;
  }

  getSpecimenType(filter: QcLJSelectedFilter, data: QcCommunicationModel): string[] {
    let specTypes: string[] = [];
    for (let qcData of data.data.qcDetails.qcDataList) {
      for (let result of qcData.qcResults) {
        //Extract the date portion excluding Zone info and time without any conversions, considering result time as it is from API/DB
        const resultDate = moment(result.time.split('T')[0], 'YYYY-MM-DD', true).toDate();
        const dateMatch = filter.earliestDate <= resultDate && filter.latestDate >= resultDate;
        const testMatch = filter.test.name === qcData.assay;
        if (dateMatch && testMatch) {
          specTypes.push(qcData.specType);
        }
      }
    }
    return specTypes;
  }

  getUnits(filter: QcLJSelectedFilter, data: QcCommunicationModel): string[] {
    let units: string[] = [];
    for (let qcData of data.data.qcDetails.qcDataList) {
      for (let result of qcData.qcResults) {
        //Extract the date portion excluding Zone info and time without any conversions, considering result time as it is from API/DB
        const resultDate = moment(result.time.split('T')[0], 'YYYY-MM-DD', true).toDate();
        const dateMatch = filter.earliestDate <= resultDate && filter.latestDate >= resultDate; const specMatch = filter.specimenType === qcData.specType;
        const testMatch = filter.test.name === qcData.assay;
        if (dateMatch && testMatch && specMatch) {
          units.push(qcData.unit);
        }
      }
    }
    return units;
  }

  getQcLotLevels(filter: QcLJSelectedFilter, data: QcCommunicationModel): string[] {
    let qcLotLevels: string[] = [];
    for (let qcData of data.data.qcDetails.qcDataList) {
      for (let result of qcData.qcResults) {
        //Extract the date portion excluding Zone info and time without any conversions, considering result time as it is from API/DB
        const resultDate = moment(result.time.split('T')[0], 'YYYY-MM-DD', true).toDate();
        const dateMatch = filter.earliestDate <= resultDate && filter.latestDate >= resultDate; const specMatch = filter.specimenType === qcData.specType;
        const testMatch = filter.test.name === qcData.assay;
        const unitsMatch = filter.units === qcData.unit;
        if (dateMatch && testMatch && specMatch && unitsMatch) {
          qcLotLevels.push(qcData.qcLotLevel);
        }
      }
    }
    return qcLotLevels;
  }

  getReagentLots(filter: QcLJSelectedFilter, data: QcCommunicationModel): string[] {
    let reagentLots: string[] = [];
    for (let qcData of data.data.qcDetails.qcDataList) {
      for (let result of qcData.qcResults) {
        //Extract the date portion excluding Zone info and time without any conversions, considering result time as it is from API/DB
        const resultDate = moment(result.time.split('T')[0], 'YYYY-MM-DD', true).toDate();
        const dateMatch = filter.earliestDate <= resultDate && filter.latestDate >= resultDate;
        const specMatch = filter.specimenType === qcData.specType;
        const testMatch = filter.test.name === qcData.assay;
        const unitsMatch = filter.units === qcData.unit;
        const qcLotLevelMatch = filter.qcLotLevel.has(qcData.qcLotLevel);
        if (dateMatch && testMatch && specMatch && unitsMatch && qcLotLevelMatch) {
          reagentLots.push(result.rgtLot);
        }
      }
    }
    return reagentLots;
  }

  getCalLots(filter: QcLJSelectedFilter, data: QcCommunicationModel): string[] {
    let calLots: string[] = [];
    for (let qcData of data.data.qcDetails.qcDataList) {
      for (let result of qcData.qcResults) {
        //Extract the date portion excluding Zone info and time without any conversions, considering result time as it is from API/DB
        const resultDate = moment(result.time.split('T')[0], 'YYYY-MM-DD', true).toDate();
        const dateMatch = filter.earliestDate <= resultDate && filter.latestDate >= resultDate;
        const specMatch = filter.specimenType === qcData.specType;
        const testMatch = filter.test.name === qcData.assay;
        const unitsMatch = filter.units === qcData.unit;
        const qcLotLevelMatch = filter.qcLotLevel.has(qcData.qcLotLevel);
        if (dateMatch && testMatch && specMatch && unitsMatch && qcLotLevelMatch) {
          calLots.push(result.calLot);
        }
      }
    }
    return calLots;
  }

  getYMinValue(qcRef: QcReference): number {
    return qcRef.sd === 0 ? qcRef.mean - 1 : Math.min(qcRef.sd3Low - (qcRef.sd * 3));
  }

  getYMaxValue(qcRef: QcReference): number {
    return qcRef.sd === 0 ? qcRef.mean + 1 : Math.max(qcRef.sd3Up + (qcRef.sd * 3));
  }

  filterOutOfBoundValues(qcResults: QcResult[], qcRef: QcReference | undefined): QcResult[] {
    if (qcRef) {
      const filteredResults = qcResults.filter(qcResult => {
        return !(qcResult.value > this.getYMaxValue(qcRef) || qcResult.value < this.getYMinValue(qcRef));
      });
      return filteredResults;
    } else {
      return qcResults;
    }
  }

}
