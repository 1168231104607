import { createFeature, createReducer, on } from '@ngrx/store';
import { GlobalState, initialGlobalState } from './global.state';
import { GlobaStateDataActions } from './global.actions';

export const globalStateReducer = createReducer<GlobalState>(
  initialGlobalState,
  on(
    GlobaStateDataActions.fetchFeatureFlagSuccess,
    (state, { releaseFlagResponse }): GlobalState => ({
      ...state,
      releaseFeatureFlags: releaseFlagResponse,
    })
  )
);

export const globalStateFeature = createFeature({
  name: 'GlobalFeatureState',
  reducer: globalStateReducer,
});
