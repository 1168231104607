import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocationSelectorFilterService {


  normalizeInput(input: string): string {
    return input.replace(/\s+/g, '').toLowerCase();
  }

  // Generic filtering function for any list with a custom normalization function
  private filterList<T>(list: T[], searchText: string, normalizeFn: (input: T) => string): T[] {
    if (!searchText) return [...list];  // Return the full list if no search term
    return list.filter(item => normalizeFn(item).includes(this.normalizeInput(searchText)));  // Apply normalization and filter
  }

  // Function for filtering cities using the filterList method
  filterCities(cityList: string[], selectedCityList: string[], searchText: string): string[] {
    if (!searchText) return cityList;

    // Filter the cities using the filterList method
    let filteredCities = this.filterList(cityList, searchText, this.normalizeInput);

    // Merge selected cities while avoiding duplicates
    const selectedCitiesSet = new Set(selectedCityList);
    filteredCities = filteredCities.filter(city => !selectedCitiesSet.has(city));

    // Return sorted list with selected cities at the end
    return [
      ...filteredCities,
      ...selectedCityList
    ].sort((a, b) => a.localeCompare(b));
  }

  // Function for filtering countries using the filterList method
  filterCountries(countryList: string[], searchText: string): string[] {
    return this.filterList(countryList, searchText, this.normalizeInput);
  }

  // Function for filtering customers using the filterList method
  filterCustomers(customerList: Set<string>, searchText: string): Set<string> {
    // Convert Set to array for filtering
    const customerArray = [...customerList];
    const filteredCustomers = this.filterList(customerArray, searchText, this.normalizeInput);
    return new Set(filteredCustomers);
  }
}
