import { Component, ViewChild, WritableSignal } from '@angular/core';
import {
  QCDateFilter,
  QcFilter,
  QCStat
} from 'src/app/model/qc-dashboard.model';
import {
  Chart,
  ChartConfiguration,
  ChartEvent,
  ChartItem,
} from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
import { DeviceListService } from 'src/app/services/device-list.service';
import { QcDashboardService } from 'src/app/services/qc-dashboard.service';
import { filter, Subscription } from 'rxjs';
import { QcDataStoreService } from 'src/app/services/qc-data-store.service';
import { QcCommunicationModel } from 'src/app/model/qc.communication.model';
import { LocationSelectorComponent } from '../location-selector/location-selector.component';
import { TestInfo } from 'src/app/model/test-info.model';
import { Router, ActivatedRoute } from '@angular/router';
import { ModuleType } from 'src/app/model/module-list.model';
import { QcService } from 'src/app/services/qc.service';
import moment from 'moment';

/**
 *  QC Dashboard provides details of Assay for Device based on the user selection.
 *
 * @export
 * @class QcDashboardComponent
 */
@Component({
  selector: 'app-qc-dashboard',
  templateUrl: './qc-dashboard.component.html',
  styleUrls: ['./qc-dashboard.component.scss'],
})
export class QcDashboardComponent {
  showFullPageLoader: boolean = true;
  showDataLoader: boolean = false;

  @ViewChild('locationSelector', { static: false }) loc!: LocationSelectorComponent;
  donutChart: Chart | undefined;
  barChart: Chart | undefined;

  // holds One Month Data
  data: QcCommunicationModel | undefined;
  filteredData!: QCStat[];
  assayList: TestInfo[] = [];
  selectedFilters: QcFilter = new QcFilter();
  defaultFilters: QCDateFilter;

  qcDashboardDataSubscription!: Subscription;
  qcFilterSubscription!: Subscription;
  testList: TestInfo[] = [];
  selectedModuleType: WritableSignal<ModuleType | null>;

  showMessage: boolean = false;
  noteMessage: string = '';
  getDataForSelectedDate: boolean = false;
  constructor(
    private qcDashboardService: QcDashboardService,
    private qcStoreService: QcDataStoreService,
    public deviceListService: DeviceListService,
    public router: Router,
    public qcService: QcService,
    private route: ActivatedRoute
  ) {
    this.selectedModuleType = this.deviceListService.selectedModuleType;
    Chart.register(annotationPlugin);

    this.defaultFilters = this.qcService.getDefaultDateRange();
    this.defaultDateFilter();

  }
  getRouteParam() {
    this.qcService.getQCRouteParam(this.route);
  }

  /** QC Dashboard Component
   * Page initialization,
   * set the loader
   * get QC Data
   * @memberof QcDashboardComponent
   */
  async ngOnInit() {
    this.getRouteParam();
    this.setDefaultFilter();
    this.subscribeToQCData();
    if (this.selectedFilters.deviceId) {
      this.showDataLoader = true;
      await this.qcDashboardService.getData(this.selectedFilters.deviceId, this.selectedFilters.dateRangeString);
    }
  }

  /**
   * destroy subscription in Destroy Lifecycle of component
   *
   * @memberof QcDashboardComponent
   */
  ngOnDestroy(): void {
    this.qcFilterSubscription?.unsubscribe();
    this.qcDashboardDataSubscription?.unsubscribe();
  }

  onLocationSelectorInitializationComplete($event: boolean) {
    this.showFullPageLoader = false;
  }

  /**
   * set default Filters
   *
   * @memberof QcDashboardComponent
   */
  setDefaultFilter() {
    this.qcStoreService.qcFilter$.subscribe((filter) => {
      if (filter) {
        this.selectedFilters.deviceId = filter.deviceId
        this.selectedFilters.assay = filter.assay;
      }
    });

    this.defaultDateFilter();
  }

  defaultDateFilter() {
    const [earliestDate, latestDate] = this.defaultFilters.defaultDateRangeString?.split('-') || [];
    this.selectedFilters.earliestDate = moment(earliestDate).toDate();
    this.selectedFilters.latestDate =  moment(latestDate).toDate();
    this.selectedFilters.dateRangeString = this.defaultFilters.defaultDateRangeString ?? '';
    this.noteMessage = `*Calculated based on QC results from ${this.selectedFilters.dateRangeString}`;
  }

  /**
   * get QC Data
   *
   * @memberof QcDashboardComponent
   */
  subscribeToQCData() {
    this.qcDashboardDataSubscription = this.qcStoreService.qcData$
      .pipe(filter((data) => data !== undefined))
      .subscribe((data) => {
        this.data = data?.get(this.selectedFilters.deviceId);
        if (this.data?.data.qcDetails.serialNo == this.selectedFilters.deviceId) {
          this.filterData();
          this.bindChart();
        }
      });
  }

  /**
   * On Search Button Click
   * call API if serial number is changed
   * @memberof QcDashboardComponent
   */
  async searchQCData() {
    if (
      this.selectedFilters.dateRangeString != undefined &&
      this.selectedFilters.dateRangeString != '' &&
      this.selectedFilters.deviceId != ''
    ) {
      this.showDataLoader = true;
        this.filteredData = [];
        await this.qcDashboardService.getData(this.selectedFilters.deviceId, this.defaultFilters?.selectedDateRangeString, this.selectedFilters.endOfCalendar); 
        this.showDataLoader = false;         
    }
  }

  /** reset Filter values
   *
   * @param {boolean} resetFlag
   * @memberof QcDashboardComponent
   */
  setFilterValues(resetFlag: boolean) {
    if (resetFlag) {
      this.selectedFilters.deviceId = this.deviceListService.selectedDeviceId;
      this.loc.inputDeviceId = this.deviceListService.selectedDeviceId;
      this.loc.resetToDefault();
      this.defaultFilters.selectedDateRangeString = this.defaultFilters.defaultDateRangeString;
    }
    this.defaultDateFilter();
  }

  /**
   * calls to draw donut chart and Bar Chart
   *
   * @memberof QcDashboardComponent
   */
  bindChart() {
    let donutData = this.filteredData.find(
      (qcStat) => qcStat.assayName == this.selectedFilters.assay
    );

    if (this.filteredData) {
      this.drawBarChart();
      this.drawDonutChart(donutData);
    }
    this.showDataLoader = false;
  }

  /**
   * navigate to QC Review
   * on Review QC click
   *
   * @memberof QcDashboardComponent
   */
  navigateToQcReview() {
    this.qcStoreService.updateFilter(this.selectedFilters);
    this.qcStoreService.updateQcReviewFilter(undefined);
    this.router.navigate(['troubleshooting', 'QC', 'qc-review'], {
      queryParamsHandling: 'merge'
    });
  }


  /**
   * Move to Service
   * process data
   * @return {*}
   * @memberof QcDashboardComponent
   */
  filterData() {
    this.filteredData = [];

    if (!this.data) return;

    const deviceId = this.data.data.qcDetails.serialNo;
    if (deviceId !== this.selectedFilters.deviceId) return;

    const dictQC = new Map();
    const earliestDate = this.selectedFilters.earliestDate;
    const latestDate = this.selectedFilters.latestDate;

    const qcDataList = this.data.data.qcDetails.qcDataList.filter(assay => assay !== undefined);

    qcDataList.forEach(assay => {
      assay.qcResults.forEach(result => {
        let date = moment(result.time.split('T')[0], 'YYYY-MM-DD', true).toDate();
        if (!this.isWithinDateRange(date, earliestDate, latestDate)) return;

        const counts = this.getResultCount(result.value, assay.qcReferences);

        const { passCount, twoSDCount, threeSDCount, totalCount } = counts;

        const key = assay.assayName;
        if (dictQC.has(key)) {
          const model = dictQC.get(key);
          model.pass += passCount;
          model.twoSD += twoSDCount;
          model.threeSD += threeSDCount;
          model.total += totalCount;
        } else {
          dictQC.set(key, {
            deviceID: deviceId,
            assayName: assay.assayName,
            assayCode: assay.assay,
            pass: passCount,
            twoSD: twoSDCount,
            threeSD: threeSDCount,
            total: totalCount,
          });
        }
      });

    });

    this.filteredData = Array.from(dictQC.values()).map(model => ({
      ...model,
      pass: (model.pass * 100) / model.total,
      twoSD: (model.twoSD * 100) / model.total,
      threeSD: (model.threeSD * 100) / model.total,
    }));

    this.getAssays();

  }

  isWithinDateRange(resultTime: Date, earliestDate: Date, latestDate: Date) {
    let date = moment(resultTime, 'YYYY-MM-DD', true).toDate();
    return date >= earliestDate && date <= latestDate;
  }


  getResultCount(resultVal: number, qcReferences: { sd2Low: number; sd2Up: number; sd3Low: number; sd3Up: number; }) {
    let passCount = 0, twoSDCount = 0, threeSDCount = 0, totalCount = 0;

    if (qcReferences.sd2Low <= resultVal && resultVal <= qcReferences.sd2Up) {
      passCount++;
      totalCount++;
    } else if (qcReferences.sd3Low <= resultVal && resultVal <= qcReferences.sd3Up) {
      twoSDCount++;
      totalCount++;
    } else {
      threeSDCount++;
      totalCount++;
    }

    return { passCount, twoSDCount, threeSDCount, totalCount };
  }


  /**Filter Component.
   * triggered on SerialNo Change Event , sets selected device.
   *
   * @param {string} deviceId
   * @memberof QcDashboardComponent
   */
  serialNoChange(deviceId: string) {
    if (this.showDataLoader || this.showFullPageLoader) return;
    this.selectedFilters.deviceId = deviceId;
  }


  /**
   * triggered on date change event.
   * Splits detesting to earliest and latest date.
   * @param {*} event
   * @memberof QcDashboardComponent
   */
  dateRangeChange(event: any) {
    this.selectedFilters.dateRangeString = event.target.value;
    this.selectedFilters.selectedDateRangeString = event.target.value;
    this.selectedFilters.earliestDate = moment(
      this.selectedFilters.dateRangeString.split('-')[0]
    ).toDate();
    this.selectedFilters.latestDate = moment(
      this.selectedFilters.dateRangeString.split('-')[1]
    ).toDate();
  }

  /**
   * Resets Filter
   *
   * @memberof QcDashboardComponent
   */
  resetFilter() {
    this.setFilterValues(true);
  }

  // Donut Chart Component
  /**
   * searches selected assay in assay List
   * updates Bar Chart
   * Sets a scroll
   * highlights selected Assay in bar chart
   * @param {string} code
   * @memberof QcDashboardComponent
   */
  searchAssay(code: string) {
    if (this.showDataLoader) return;

    if (this.selectedFilters.assay != '') {
      let index = this.barChart?.data?.labels?.findIndex(
        (i) => i == code
      );
      this.setBarColors(index);
      this.barChart?.update();
      this.moveScroll(index);
      let donutData = this.filteredData.find(
        (qcStat) => qcStat.assayName == this.selectedFilters.assay
      );
      this.drawDonutChart(donutData);
    }

  }

  /**
   * Process assay an assign to search array
   *
   * @memberof QcDashboardComponent
   */
  getAssays() {
    this.assayList = [];
    this.filteredData.forEach((element) => {
      if (
        !this.assayList.some((model) => model.longName === element.assayName)
      )
        this.assayList.push({
          longName: element.assayName,
          name: element.assayCode,
        });

      //verify
      if (element.assayCode == this.selectedFilters.assay) {
        this.selectedFilters.assay = element.assayName;
      }
    });

    let el = document.getElementById('QCDashboard_Assay_Search');
    if (el)
      el?.setAttribute(
        'search-array',
        JSON.stringify(this.assayList.map((x) => {
          return {
            name: x.longName,
            code: x.name
          }
        }))
      );
  }

  /**
   * triggered on assay change event
   *
   * @param {*} event
   * @memberof QcDashboardComponent
   */
  assayChange(event: any) {
    if (event.target.searchArray.length != this.assayList.length) {
      this.getAssays();
    }
    let testInfo = event.target.searchArray.find((x: any) => {
      if (x.name === event.target.value) return true;
      return false;
    })

    if (testInfo) {
      this.selectedFilters.assay = testInfo.name;
      this.searchAssay(testInfo.code);
    }
  }

  /**
   * Draw Donut Chart for filtered data
   *
   * @param {(QCStat | undefined)} donutData
   * @memberof QcDashboardComponent
   */
  drawDonutChart(donutData: QCStat | undefined) {
    let chartData: any = {
      datasets: [],
    };
    let chartOptions: ChartConfiguration['options'];

    if (donutData != undefined) {
      chartData = {
        labels: [
          'Percentage QC Pass',
          'Percentage QC 2S errors',
          'Percentage QC 3S errors',
        ],
        datasets: [
          {
            type: 'doughnut',
            label: donutData.assayName,
            data: [donutData.pass, donutData.twoSD, donutData.threeSD],
            borderWidth: 2,
            cutout: '65%',
            backgroundColor: ['#33AF33', '#FCF500', '#FF0044'],
            spacing: 1,
          },
        ],
      };

      chartOptions = {
        plugins: {
          legend: {
            position: 'right',
            display: true,
            align: 'center',
            labels: {
              pointStyle: 'rect',
              usePointStyle: true,
              color: 'black',
              font: {
                size: 20,
                family: 'normal normal bold Siemens Sans',
              },
            },
          },
          title: {
            text: donutData.assayName,
            display: false,
            color: 'black',
            position: 'top',
            font: {
              size: 30,
              family: 'Siemens Sans',
            },
          },
        },
        maintainAspectRatio: false,
      };
    }

    setTimeout(() => {
      if (this.donutChart) {
        this.donutChart.destroy();
      }
      // Get the canvas element
      let ctx = document.getElementById('QCDashboard_DonutChart_Canvas') as ChartItem;
      // Create the donut chart
      if(ctx == null) return;
      this.donutChart = new Chart(ctx, {
        type: 'doughnut',
        data: chartData,
        options: chartOptions,
      });
    }, 0);
  }


  /**Bar Chart
   * Draw bar Chart for filtered data
   *
   * @memberof QcDashboardComponent
   */
  drawBarChart() {
    let barData = this.getBarChartData();
    const chartData: ChartConfiguration['data'] = {
      labels: barData.ThreesBarColoringMetaData.map((item) => item.assay),
      datasets: [
        {
          label: 'Percentage QC Pass',
          data: barData.passArray,
          backgroundColor: barData.PassBarColoringMetaData.map((item) => item.color),
        },

        {
          label: 'Percentage QC 2S errors',
          data: barData.twosArray,
          backgroundColor: barData.TwosBarColoringMetaData.map((item) => item.color),
        },
        {
          label: 'Percentage QC 3s errors',
          data: barData.threesArray,
          backgroundColor: barData.ThreesBarColoringMetaData.map((item) => item.color),
        },
      ],
    };

    const chartOptions: any = {
      barThickness: 25,
      barPercentage: 0.1,
      categoryPercentage: 0.1,
      plugins: {
        legend: {
          display: false,
          labels: {
            usePointStyle: true,
          },
        },
      },
      scales: {
        x: {
          stacked: true,
          grid: {
            display: false,
            drawTicks: true,
            tickColor: 'black',
          },
          title: {
            text: 'Assays',
            display: true,
            align: 'center',
            color: '000000E6',
            font: {
              size: 18,
              weight: 'bold',
              family: 'Siemens Sans',
            },
          },
        },
        y: {
          min: 0,
          max: 100,
          stacked: true,
          grid: {
            drawOnChartArea: true,
            display: false,
            tickColor: 'black',
          },
          title: {
            text: 'QC Pass/Error distribution %',
            display: true,
            color: '000000E6',
            font: {
              size: 18,
              weight: 'bold',
              family: 'Siemens Sans',
            },
          },
        }

      },

      onClick: (event: ChartEvent, activePoints: any[]) => {
        if (activePoints.length > 0) {
          const clickedElementIndex = activePoints[0].index;
          this.drawDonutChart(this.filteredData[clickedElementIndex]);
          this.selectedFilters.assay =
            this.filteredData[clickedElementIndex].assayName;
          let e2 = document.getElementById('QCDashboard_Assay_Search');
          e2?.setAttribute('value', this.selectedFilters.assay);

          this.setBarColors(activePoints[0].index);
          this.barChart?.update();
          this.moveScroll(activePoints[0].index);
        }
      },
      maintainAspectRatio: false,
    };

    setTimeout(() => {
      if (this.barChart) {
        this.barChart.destroy();
      }
      // Get the canvas element
      let ctx = document.getElementById('QCDashboard_BarChart_Canvas') as ChartItem;

      const containerBody = document.querySelector<any>('.container-body');
      // Create the donut chart
      this.barChart = new Chart(ctx, {
        type: 'bar',
        data: chartData,
        options: chartOptions,
      });

      if (this.barChart.data.labels) {
        const totalLabels = this.barChart.data.labels?.length
        const newWidth = 0 + ((totalLabels) * 4)
        if (containerBody)
          containerBody.style.width = `${newWidth}%`;
      }
      this.moveScroll(barData.highlightIndex);
    }, 0);
  }

  private getBarChartData() {
    let passArray = [];
    let twosArray = [];
    let threesArray = [];

    let ThreesBarColoringMetaData: {
      percentage: number;
      color: string;
      assay: string;
    }[] = [];

    let TwosBarColoringMetaData: {
      percentage: number;
      color: string;
      assay: string;
    }[] = [];

    let PassBarColoringMetaData: {
      percentage: number;
      color: string;
      assay: string;
    }[] = [];

    this.filteredData.sort((a, b) => {
      const threeSDComparison = b.threeSD - a.threeSD;
      if (threeSDComparison !== 0) {
        return threeSDComparison;
      }

      const twoSDComparison = b.twoSD - a.twoSD;
      if (twoSDComparison !== 0) {
        return twoSDComparison;
      }

      const passComparison = a.pass - b.pass;
      if (passComparison !== 0) {
        return passComparison;
      }
      return threeSDComparison;
    });

    let highlightIndex = -1;
    for (let i = 0; i < this.filteredData.length; i++) {
      let item = this.filteredData[i];
      let threeSDColor = '#FF004460';
      let twoSDColor = '#FCF50060';
      let passColor = '#33AF3360';

      if (item.assayName == this.selectedFilters.assay) {
        highlightIndex = i;
        threeSDColor = '#FF0044';
        twoSDColor = '#FCF500';
        passColor = '#33AF33';
      }

      ThreesBarColoringMetaData.push({
        percentage: item.threeSD,
        color: threeSDColor,
        assay: item.assayCode,
      });

      TwosBarColoringMetaData.push({
        percentage: item.twoSD,
        color: twoSDColor,
        assay: item.assayCode,
      });

      PassBarColoringMetaData.push({
        percentage: item.pass,
        color: passColor,
        assay: item.assayCode,
      });

      threesArray.push(item.threeSD);
      twosArray.push(item.twoSD);
      passArray.push(item.pass);
    }
    return {
      passArray,
      twosArray,
      threesArray,
      PassBarColoringMetaData,
      TwosBarColoringMetaData,
      ThreesBarColoringMetaData,
      highlightIndex
    }
  }

  /**
   * Move scroll when assay is changed
   *
   * @param {*} index
   * @memberof QcDashboardComponent
   */
  moveScroll(index: any) {
    const chartContainer = document.getElementById('QCDashboard_barChart_Div');
    if (this.barChart && chartContainer && index != -1) {
      const barWidth =
        (chartContainer?.scrollWidth ?? 0) /
        (this.barChart?.data.labels?.length ?? 1);

      /* Calculates the scroll Position by multiplying bar width and index of the bar ,
        calculate the scroll position such that the selected bar appears approximately in the middle of the chart container.
        */
      const scrollPosition = index * barWidth - chartContainer.offsetWidth / 2 + barWidth / 2;
      chartContainer.scrollLeft = scrollPosition;
    }
  }

  /**
   * sets bar color
   *
   * @param {*} index
   * @return {*}
   * @memberof QcDashboardComponent
   */
  setBarColors(index: any) {
    if (index == -1 || !this.barChart) return;
    let threesDataset = this.barChart?.config?.data?.datasets[2];
    let twosDataset = this.barChart?.config?.data?.datasets[1];
    let passDataset = this.barChart?.config?.data?.datasets[0];

    for (let i = 0; i < (threesDataset?.backgroundColor as Array<string>).length; i++) {
      if (i === index) {
        (threesDataset?.backgroundColor as Array<string>)[i] = '#FF0044';
      } else {
        (threesDataset?.backgroundColor as Array<string>)[i] = '#FF004460';
      }
    }

    for (
      let i = 0;
      i < (twosDataset?.backgroundColor as Array<string>).length;
      i++
    ) {
      if (i === index) {
        (twosDataset?.backgroundColor as Array<string>)[i] = '#FCF500';
      } else {
        (twosDataset?.backgroundColor as Array<string>)[i] = '#FCF50060';
      }
    }

    for (
      let i = 0;
      i < (passDataset?.backgroundColor as Array<string>).length;
      i++
    ) {
      if (i === index) {
        (passDataset?.backgroundColor as Array<string>)[i] = '#33AF33';
      } else {
        (passDataset?.backgroundColor as Array<string>)[i] = '#33AF3360';
      }
    }
  }

  applyDateRange(_event: any) {  
   console.log('apply clicked');
  }

  onDatePickerClick(event: any) {
    const el = document.getElementById('applyBtn');
    const value = event.target.__selectedDates;
    el?.setAttribute('disabled', 'disabled');
    if (value?.length !== 2) return;

    const [startDate, endDate] = value;
    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) return;
  
    const dayDiff = Math.round((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)) + 1;
    const isWithinRange = (dayDiff >= 7 && dayDiff <= 30);

    if(isWithinRange){
      el?.removeAttribute('disabled');
      this.showMessage = false;
    }
    else{
      el?.setAttribute('disabled', 'disabled');
      this.showMessage = true;
    }
    const isNotDisableDate = this.qcDashboardService.getPreviousOneMonthDate(endDate) >= this.qcDashboardService.convertToMMDDYYYY(event.target.__minDate);

    this.noteMessage = (!isNotDisableDate && dayDiff <= 30)
      ? `*Calculated based on QC results from ${this.qcDashboardService.convertToMMDDYYYY(startDate)} - ${this.qcDashboardService.convertToMMDDYYYY(endDate)}`
      : `*Calculated based on QC results from ${this.qcDashboardService.getPreviousOneMonthDate(endDate)} - ${this.qcDashboardService.convertToMMDDYYYY(endDate)}`;

    this.selectedFilters.endOfCalendar = (!isNotDisableDate && dayDiff <= 30);
    this.defaultFilters.selectedDateRangeString = `${this.qcDashboardService.convertToMMDDYYYY(startDate)}-${this.qcDashboardService.convertToMMDDYYYY(endDate)}`;
  }
}
