export enum DataType {
  None = 0,
  ModuleTypes = 1,
  ModulesData = 2,
  QCData = 3,
  QCFleetData = 4,
  QuestionnaireData = 5,
  AssaysData = 6,
  Calibration = 7,
  TransferArmAnalysis = 8,
  NotificationData = 9,
  QuestionnaireStatus = 10,
  FeatureFlag = 11,
}
