import { Component, OnInit, ViewChild, WritableSignal } from '@angular/core';
import { Chart, ChartConfiguration, ChartItem, LegendItem } from "chart.js/auto";
import annotationPlugin from 'chartjs-plugin-annotation';
import 'chartjs-adapter-luxon';
import { QcLJSelectedFilter } from 'src/app/model/qc-l-j-filter.model';
import { QcLJGraphMetaData } from 'src/app/model/QcLJGraphMetaData.model';
import { DeviceListService } from 'src/app/services/device-list.service';
import { QcReviewService } from 'src/app/services/qc-review.service';
import { QcCommunicationModel, QcReference } from 'src/app/model/qc.communication.model';
import { QcFilter } from 'src/app/model/qc-dashboard.model';
import { LocationSelectorComponent } from '../location-selector/location-selector.component';
import { TestInfo } from 'src/app/model/test-info.model';
import { FleetComparisonService } from 'src/app/services/fleet-comparison.service';
import { FleetCompRequestModel } from 'src/app/model/fleet-comparison-communication.model';
import { MiscLJChartDataModel } from 'src/app/model/misc-lj-chart-data.model';
import { QcComparisonService } from 'src/app/services/qc-comparison.service';
import { LJGraphData } from 'src/app/model/lj-graph-data.model';
import { ModuleType } from 'src/app/model/module-list.model';
import { QcService } from 'src/app/services/qc.service';
import moment from 'moment';

@Component({
  selector: 'app-qc-review',
  templateUrl: './qc-review.component.html',
  styleUrls: ['./qc-review.component.scss']
})
export class QcReviewComponent implements OnInit {
  charts: Chart[] = [];
  Math = Math;
  @ViewChild('locationSelector', { static: false }) loc!: LocationSelectorComponent;
  selectedFilter: QcLJSelectedFilter = {
    serial: '',
    test: {
      name: '',
      longName: ''
    },
    units: '',
    qcLotLevel: new Set<string>(),
    reagentLot: '',
    calLot: '',
    latestDate: new Date(0),
    earliestDate: new Date(0),
    specimenType: '',
    dateRangeString: '',
    assay:'',
  }
  filteredData: QcLJGraphMetaData[] = [];
  data: QcCommunicationModel | undefined;
  assayList: Map<string, string> = new Map<string, string>();
  specimenList: Set<string> = new Set<string>();
  unitsList: Set<string> = new Set<string>();
  qcLotLevelList: Set<string> = new Set<string>();
  reagentLotList: Set<string> = new Set<string>();
  calLotList: Set<string> = new Set<string>();
  latestDateString: string = '';
  earliestDateString: string = '';
  defaultQcFilter!: QcFilter;
  dataPointColors = ['#8F1AFF', '#009B00', '#006ADD', '#FFD200', '#EC6602', '#000000', '#707070'];
  gettingNewData = false;
  initializingData = true;
  deviceId: string = '';
  initializingLocation = true
  selectedModuleType: WritableSignal<ModuleType | null>;

  showMessage: boolean = false;
  disableApplyBtn: boolean = false;
  noteMessage: string = '';
  showNoteMsg: boolean = false;
  showQcStatsNote: string = '';
  constructor(
    public qcReviewService: QcReviewService,
    public deviceListService: DeviceListService,
    private fleetCompService: FleetComparisonService,
    private qcComparisonService: QcComparisonService,
    private qcService: QcService
  ) {
    Chart.register(annotationPlugin);
    this.selectedModuleType = this.deviceListService.selectedModuleType;
    ({ latestDateString: this.latestDateString, earliestDateString: this.earliestDateString } = this.qcService.getDefaultDateRange());
    this.showQcStatsNote = `${this.selectedFilter?.dateRangeString}`;
  }

  async ngOnInit() {
    this.initializingData = true;
    try {
      await this.setInitialFilters();
      this.getGraphs();
    }
    catch (err) {
      console.log('QcReviewComponent : ngAfterViewInit :', err);
    }
    finally {
      this.initializingData = false;
      this.showNoteMsg = true;
    }    
  }

  onLocationSelectorInitializationComplete($event: boolean) {
    this.initializingLocation = false;
  }

  /**
   * Set the initial filters, when the component is initialized
   * Filters from store is applied if present, else default filters are applied.
   */
  async setInitialFilters() {
    this.defaultQcFilter = await this.qcReviewService.getDefaultFilter();
    const storedQcReviewFilters = await this.qcReviewService.getQcReviewFilters();
    storedQcReviewFilters ? await this.setQcReviewFiltersFromStore(storedQcReviewFilters) : await this.setDefaultLJFilter();
  }

  /**
   * Sets the filters for the page, which is retrieved from the store.
   * @param qcReviewFilters filters of the QC review page
   */
  async setQcReviewFiltersFromStore(qcReviewFilters: QcLJSelectedFilter) {
    try {
      this.gettingNewData = true;
      this.selectedFilter = qcReviewFilters;
      this.deviceId = this.selectedFilter.serial;
      this.loc.inputDeviceId = this.deviceId;
      this.loc.resetToDefault();
      this.data = await this.qcReviewService.getData(this.deviceId, this.defaultQcFilter?.selectedDateRangeString!, this.defaultQcFilter.endOfCalendar);
      (document.getElementById('QCReview_Filter_DatePicker') as any).value = this.selectedFilter.dateRangeString;
      this.assayList = this.qcReviewService.getAssays(this.selectedFilter, this.data);
      this.updateFilters('DefaultFilter');
      const [selectedFrom, selectedTo] = this.selectedFilter?.dateRangeString?.split('-') ?? [];
      this.noteMessage = (this.defaultQcFilter.endOfCalendar || this.qcReviewService.getDateDifference(this.selectedFilter?.dateRangeString)) ? `${this.qcReviewService.convertToMMDDYYYY(selectedFrom)} - ${this.qcReviewService.convertToMMDDYYYY(selectedTo)}`
      : `${this.qcReviewService.getPreviousOneMonthDate(selectedTo)} - ${this.qcReviewService.convertToMMDDYYYY(selectedTo)}`;
    } catch (error) {
      console.log(error);
    }
    finally {
      this.gettingNewData = false;
    }
  }

  /**
   * Sets the default filters, based on the filters stored from QC dashboard page
   */
  async setDefaultLJFilter() {
    try {
      this.gettingNewData = true;
      this.deviceId = this.defaultQcFilter.deviceId;
      this.loc.inputDeviceId = this.deviceId;
      this.loc.resetToDefault();
      //get data based on device id
      this.data = await this.qcReviewService.getData(this.defaultQcFilter.deviceId, this.defaultQcFilter?.dateRangeString, this.defaultQcFilter.endOfCalendar);
      //set default serial no and date range
      this.selectedFilter.serial = this.defaultQcFilter.deviceId;
      this.selectedFilter.dateRangeString = this.defaultQcFilter.dateRangeString;
      (document.getElementById('QCReview_Filter_DatePicker') as any).value = this.selectedFilter.dateRangeString;
      this.selectedFilter.earliestDate = this.defaultQcFilter.earliestDate;
      this.selectedFilter.latestDate = this.defaultQcFilter.latestDate;
      this.assayList = this.qcReviewService.getAssays(this.selectedFilter, this.data);
      //REMOVE CODE AFTER DASHBOARD SENDS ASSAY CODE
      this.selectedFilter.test = this.getAssayData(this.defaultQcFilter.assay);
      this.updateFilters('DefaultFilter');
      const [selectedFrom, selectedTo] = this.selectedFilter?.dateRangeString?.split('-') ?? [];
      this.noteMessage = (this.defaultQcFilter.endOfCalendar || this.qcReviewService.getDateDifference(this.selectedFilter?.dateRangeString)) ? `${this.qcReviewService.convertToMMDDYYYY(selectedFrom)} - ${this.qcReviewService.convertToMMDDYYYY(selectedTo)}`
      : `${this.qcReviewService.getPreviousOneMonthDate(selectedTo)} - ${this.qcReviewService.convertToMMDDYYYY(selectedTo)}`;
      //END REMOVE CODE
    }
    catch (err) {
      console.log(err);
    }
    finally {
      this.gettingNewData = false;
    }
  }

  /**
   * Get Assay data from the selected assay in string.
   * @param assayName Assay name for which data needs to be retrieved
   */
  getAssayData(assayName: string): TestInfo {
    let contains = this.assayList.has(assayName);
    let tempFilter: TestInfo = { name: '', longName: '' };
    if (!contains) {
      let list = [...this.assayList];
      let index = list.findIndex((x) => x[1] === assayName);
      if (index !== -1) {
        tempFilter = { name: list[index][0], longName: list[index][1] };
      }
    }
    else {
      tempFilter = { name: assayName, longName: this.assayList.get(assayName)! };
    }
    return tempFilter;
  }

  getFilteredAssays() {
    this.assayList.clear();
    //if qc data exists, fetch list of assays
    if (this.data) {
      this.assayList = this.qcReviewService.getAssays(this.selectedFilter, this.data);
    }
    if (!this.assayList.has(this.selectedFilter.test.name)) {
      this.selectedFilter.test = { name: '', longName: '' }
    }
    let el = document.getElementById('QCReview_Filter_AssaySearch');
    el?.setAttribute('search-array', JSON.stringify(Array.from([...this.assayList].map(x => {
      return {
        name: x[0],
        longName: x[1]
      }
    }))));
  }

  getFilteredSpecimenTypes() {
    this.specimenList.clear();
    if (this.data) {
      let specimens = this.qcReviewService.getSpecimenType(this.selectedFilter, this.data);
      for (let specimen of specimens) {
        this.specimenList.add(specimen);
      }
    }
    if (!this.specimenList.has(this.selectedFilter.specimenType)) this.selectedFilter.specimenType = this.specimenList.values()?.next()?.value ?? '';
  }

  getFilteredUnits() {
    this.unitsList.clear();
    if (this.data) {
      let units = this.qcReviewService.getUnits(this.selectedFilter, this.data);
      for (let unit of units) {
        this.unitsList.add(unit);
      }
    }
    if (!this.unitsList.has(this.selectedFilter.units)) this.selectedFilter.units = this.unitsList.values()?.next()?.value ?? '';
  }

  getFilteredQcLotLevels() {
    this.qcLotLevelList.clear();
    if (this.data) {
      let qclotlevels = this.qcReviewService.getQcLotLevels(this.selectedFilter, this.data);
      for (let qcLotLevel of qclotlevels) {
        this.qcLotLevelList.add(qcLotLevel);
      }
    }
    //remove from selected list
    for (let qcLotLevel of this.selectedFilter.qcLotLevel) {
      if (!this.qcLotLevelList.has(qcLotLevel)) {
        this.selectedFilter.qcLotLevel.delete(qcLotLevel);
      }
    }
    //if its empty, default to all.
    if (this.selectedFilter.qcLotLevel.size === 0 || (this.selectedFilter.qcLotLevel.size === this.qcLotLevelList.size && this.qcLotLevelList.size > 1)) {
      this.onSelectAllQcLotLevel();
    }

    //Update label for qc lots selection
    this.onQcLotLevelValueChanged();
  }

  getFilteredReagentLots() {
    this.reagentLotList.clear();
    if (this.data) {
      let reagentlots = this.qcReviewService.getReagentLots(this.selectedFilter, this.data);
      for (let reagentlot of reagentlots) {
        this.reagentLotList.add(reagentlot)
      }
    }
    if (!this.reagentLotList.has(this.selectedFilter.reagentLot) || this.reagentLotList.size === 0) this.selectedFilter.reagentLot = 'None';
  }

  getFilteredCalLots() {
    this.calLotList.clear();
    if (this.data) {
      let callots = this.qcReviewService.getCalLots(this.selectedFilter, this.data);
      for (let callot of callots) {
        this.calLotList.add(callot)
      }
    }
    if (!this.calLotList.has(this.selectedFilter.calLot) || this.calLotList.size === 0) this.selectedFilter.calLot = 'None';
  }

  updateFilters(source: string) {
    switch (source) {
      case 'DefaultFilter':
        this.getFilteredAssays();
        this.getFilteredSpecimenTypes();
        this.getFilteredUnits();
        this.onSelectAllQcLotLevel();
        this.getFilteredQcLotLevels();
        this.getFilteredReagentLots();
        this.getFilteredCalLots();
        break;
      case 'Date':
      case 'Device':
        this.getFilteredAssays();
        this.getFilteredSpecimenTypes();
        this.getFilteredUnits();
        this.getFilteredQcLotLevels();
        this.getFilteredReagentLots();
        this.getFilteredCalLots();
        break;
      case 'Assay':
        this.getFilteredSpecimenTypes();
        this.getFilteredUnits();
        this.getFilteredQcLotLevels();
        this.getFilteredReagentLots();
        this.getFilteredCalLots();
        break;
      case 'Specimen':
        this.getFilteredUnits();
        this.getFilteredQcLotLevels();
        this.getFilteredReagentLots();
        this.getFilteredCalLots();
        break;
      case 'Units':
        this.getFilteredQcLotLevels();
        this.getFilteredReagentLots();
        this.getFilteredCalLots();
        break;
      case 'QcLotLevel':
        this.getFilteredReagentLots();
        this.getFilteredCalLots();
        break;
    }
  }

  onDateChanged(event: any) {
    if (this.initializingData || this.initializingLocation) return;
    if (event.target.value) {
      let dates = event.target.value.split('-');
      this.selectedFilter.latestDate = new Date(dates[1]);
      this.selectedFilter.earliestDate = new Date(dates[0]);
    }
    this.selectedFilter.dateRangeString = event?.target?.value;
    this.selectedFilter.assay = this.selectedFilter.test?.longName ?? '';
    this.updateFilters('Date');
  }

  async onDeviceIdChange(deviceId: string) {
    try {
      if (this.initializingData || this.initializingLocation) return;
      this.selectedFilter.serial = deviceId;
      let timeout;
      if (deviceId === '') {
        this.data = undefined;
      }
      else {
        this.gettingNewData = true;
        timeout = setTimeout(() => this.gettingNewData = false, 30000);
        this.data = await this.qcReviewService.getData(deviceId, this.defaultQcFilter?.selectedDateRangeString!);
        clearTimeout(timeout);
        this.updateFilters('Device');
      }
    }
    finally {
      this.gettingNewData = false;
    }
  }

  onAssayChanged(event: any) {
    let testInfoArr = [...this.assayList].find(x => x[1] === event.target.value);
    if (testInfoArr) {
      let testInfo: TestInfo = {
        name: testInfoArr[0],
        longName: testInfoArr[1]
      }
      this.selectedFilter.test = testInfo;
    }
    else {
      this.selectedFilter.test.name = '';
      this.selectedFilter.test.longName = '';
    }
    this.updateFilters('Assay');
  }

  onSpecimenChanged(selData: any) {
    this.selectedFilter.specimenType = selData;
    this.updateFilters('Specimen');
  }

  onUnitsChanged(units: string) {
    this.selectedFilter.units = units;
    this.updateFilters('Units');
  }

  onQcLotLevelChanged(event: any, qcLotLevel: string) {
    let selected: boolean = event.target.active;
    if (selected) {
      this.selectedFilter.qcLotLevel.add(qcLotLevel);
    }
    else {
      this.selectedFilter.qcLotLevel.delete(qcLotLevel);
    }
    for (let qcLotLevel of this.selectedFilter.qcLotLevel) {
      if (!this.qcLotLevelList.has(qcLotLevel)) {
        this.selectedFilter.qcLotLevel.delete(qcLotLevel);
      }
    }
    this.updateFilters('QcLotLevel');
  }

  onSelectAllQcLotLevelsClicked(event: any) {
    let selected = event.target.active;
    if (selected) {
      this.onSelectAllQcLotLevel();
    }
    else {
      this.selectedFilter.qcLotLevel.clear();
    }
    this.updateFilters('QcLotLevel');
  }

  onSelectAllQcLotLevel() {
    this.selectedFilter.qcLotLevel.clear();
    for (let qcLotLevel of this.qcLotLevelList) {
      this.selectedFilter.qcLotLevel.add(qcLotLevel);
    }
  }

  onQcLotLevelValueChanged() {
    let el: any = document.getElementById('QCReview_Filter_QCLotLevelDropdown');
    if (this.selectedFilter.qcLotLevel.size === this.qcLotLevelList.size && this.qcLotLevelList.size > 1) {
      el.value = 'All';
    }
    else {
      if (el._multiValue) {
        el._multiValue = [...new Set((el._multiValue as Array<string>).filter(val => this.qcLotLevelList.has(val)))];
        el.value = (el._multiValue as Array<string>).join(',');
      }
      else {
        el.__multiValue = [...new Set((el.__multiValue as Array<string>).filter(val => this.qcLotLevelList.has(val)))];
        el.value = (el.__multiValue as Array<string>).join(',');
      }

    }
  }

  onReagentLotChanged(reagentLot: string) {
    this.selectedFilter.reagentLot = reagentLot;
    if (this.selectedFilter.reagentLot === 'View All') {
      this.selectedFilter.calLot = 'None';
    }
  }

  onCalLotChanged(calLot: string) {
    this.selectedFilter.calLot = calLot;
    if (this.selectedFilter.calLot === 'View All') {
      this.selectedFilter.reagentLot = 'None';
    }
  }
  getGraphs() {
    if (this.data === undefined) return;
    this.filteredData = this.qcReviewService.getFilteredData(this.data, this.selectedFilter);
    this.filteredData.forEach(element => {
      element.qcResults = element.qcResults.filter(item => {
        //Extract the date portion excluding Zone info and time without any conversions, considering result time as it is from API/DB
        const resultDate = moment(item.time.split('T')[0], 'YYYY-MM-DD', true).toDate();
        return this.selectedFilter.earliestDate <= resultDate && this.selectedFilter.latestDate >= resultDate;
      })
    });

    //destroy charts before drawing
    for (let chart of this.charts) {
      chart.destroy();
    }
    this.charts = [];

    for (let i = 0; i < this.filteredData.length; i++) {
      let id = 'QCReview_Graph_Chart_' + i;
      this.drawGraph(this.filteredData[i], id, i);
    }

    //show info message
    this.showQcStatsNote = this.noteMessage;
  }

  updateFiltersInStore() {
    this.qcReviewService.updateFiltersInStore(this.selectedFilter);
  }

  drawGraph(item: QcLJGraphMetaData, id: string, i: number) {
    let qcRef = this.getQcRef(item, item.qcResultMin, item.qcResultMax);
    const qcResults = this.qcReviewService.filterOutOfBoundValues(item.qcResults, qcRef);
    let data: LJGraphData[] = qcResults.map((x) => {
      const resultDate = moment(x.time.split('Z')[0], true).toDate();
      return { 'x': resultDate.getTime(), 'y': x.value, tooltipLabel: resultDate.toLocaleString() + "\nValue = " + x.value + ' ' + item.unit + "\n" +((x.errFlgs == '') ? '': "Error Flag = " + x.errFlgs), color: this.dataPointColors[0], reagentLot: x.rgtLot, calLot: x.calLot, selected: '' }
    }).sort((x: any, y: any) => new Date(x.x).getTime() - new Date(y.x).getTime());

    this.assignColorsToDataPoints(data);

    const chartData: ChartConfiguration['data'] = {
      datasets: [
        {
          type: 'scatter',
          label: '',
          backgroundColor: function (dataPoint, context) {
            return dataPoint.type === 'dataset' ? '' : (dataPoint.raw as any).color;
          },
          data: data,
          pointStyle: 'circle',
          pointRadius: 5,
          showLine: true,
          borderColor: function (dataPoint, context) {
            return dataPoint.type === 'dataset' ? '' : (dataPoint.raw as any).color;
          },
          borderWidth: 1
        },
        {
          type: 'scatter',
          label: '',
          backgroundColor: function (dataPoint, context) {
            return 'rgba(0,0,0,0)';
          },
          data: [],
          pointStyle: 'circle',
          pointRadius: 5,
          showLine: false,
          borderColor: function (dataPoint, context) {
            return dataPoint.type === 'dataset' ? '' : (dataPoint.raw as any).color;
          },
          borderWidth: 2
        }
      ],
    };

    let options = this.getUpdatedOptions(qcRef, item, item.ymax, item.ymin);
    setTimeout(() => {
      //this needs to be in timeout for some reason as ctx is null without it
      let ctx = document.getElementById(id) as ChartItem;
      const scatterChartCanvas = ctx as HTMLCanvasElement;
      const scatterChartContext = scatterChartCanvas.getContext('2d') as CanvasRenderingContext2D;
      const chart = new Chart(scatterChartContext, {
        type: 'line',
        data: chartData,
        options: options,
      });
      this.charts.push(chart); //store charts to destroy later
    }, 0);
  }

  assignQcRefValuesToLJGraphData(qcRef: QcReference, item: QcLJGraphMetaData) {
    item.mean = qcRef.mean;
    item.cv = qcRef.cv;
    item.sdCalcCount = qcRef.count;
    item.sd = qcRef.sd;
    item.sD1Low = qcRef.sd1Low;
    item.sD1_Up = qcRef.sd1Up;
    item.sD2Low = qcRef.sd2Low;
    item.sD2Up = qcRef.sd2Up;
    item.sD3Low = qcRef.sd3Low;
    item.sD3Up = qcRef.sd3Up;
  }
  assignColorsToDataPoints(data: { x: number; y: number; tooltipLabel: string; color: string; reagentLot: string; calLot: string; selected: string }[]): Map<string, number> {
    let typeColorMap = new Map<string, number>();
    if (this.selectedFilter.reagentLot === 'View All') {
      this.assignReagentColors(typeColorMap, data);
    }
    else if (this.selectedFilter.calLot === 'View All') {
      this.assignCalColors(typeColorMap, data);
    }
    else {
      for (let point of data) {
        point.color = this.dataPointColors[0];
      }
    }
    return typeColorMap;
  }

  private assignCalColors(typeColorMap: Map<string, number>, data: { x: number; y: number; tooltipLabel: string; color: string; reagentLot: string; calLot: string; selected: string; }[]) {
    let i = 0;
    for (let cal of this.calLotList) {
      if (!typeColorMap.has(cal)) {
        typeColorMap.set(cal, i++);
      }
    }
    for (let point of data) {
      point.color = this.dataPointColors[typeColorMap.get(point.calLot) ?? 0];
      point.selected = point.calLot;
    }
  }

  private assignReagentColors(typeColorMap: Map<string, number>, data: { x: number; y: number; tooltipLabel: string; color: string; reagentLot: string; calLot: string; selected: string; }[]) {
    let i = 0;
    for (let rgt of this.reagentLotList) {
      if (!typeColorMap.has(rgt)) {
        typeColorMap.set(rgt, i++);
      }
    }
    for (let point of data) {
      point.color = this.dataPointColors[typeColorMap.get(point.reagentLot) ?? 0];
      point.selected = point.reagentLot;
    }
  }

  onSliderUpdate($event: any, index: number) {
    setTimeout(() => {
      let chart = this.charts[index];
      let upperVal = $event.target.upperValue;
      let lowerVal = $event.target.lowerValue;
      let data = this.filteredData[index];
      let qcRef = this.getQcRef(data, lowerVal, upperVal);
      const qcResults = this.qcReviewService.filterOutOfBoundValues(data.qcResults, qcRef);
      // filter data points and assign to chart.
      let filteredDataPoints = qcResults.filter(x => x.value <= upperVal && x.value >= lowerVal).map((x) => {
        // \n is not working. need to explore
        const resultDate = new Date(x.time.split('Z')[0]);
        return { 'x': resultDate.getTime(), 'y': x.value, tooltipLabel: resultDate.toLocaleString() + "\nValue = " + x.value + ' ' + data.unit+ "\n" +((x.errFlgs == '') ? '': "Error Flag = " + x.errFlgs), color: this.dataPointColors[0], reagentLot: x.rgtLot, calLot: x.calLot, selected: '' }
      }).sort((x: any, y: any) => new Date(x.x).getTime() - new Date(y.x).getTime());
      this.assignColorsToDataPoints(filteredDataPoints);
      chart.config.data.datasets[0].data = filteredDataPoints;
      let excludedDataPoints = qcRef.sd === 0 ? [] : qcResults.filter(x => !(x.value <= upperVal && x.value >= lowerVal)).map((x) => {
        // \n is not working. need to explore
        const resultDate = new Date(x.time.split('Z')[0]);
        return { 'x':resultDate.getTime(), 'y': x.value, tooltipLabel: resultDate.toLocaleString() + "\nValue = " + x.value + ' ' + data.unit+ "\n" +((x.errFlgs == '') ? '': "Error Flag = " + x.errFlgs), color: this.dataPointColors[0], reagentLot: x.rgtLot, calLot: x.calLot, selected: '' }
      }).sort((x: any, y: any) => new Date(x.x).getTime() - new Date(y.x).getTime());
      this.assignColorsToDataPoints(excludedDataPoints);
      chart.config.data.datasets[1].data = excludedDataPoints;
      let meanElement = document.getElementById('QCReview_Graph_MeanValue_' + index);
      (meanElement as any).innerHTML = parseFloat(qcRef.mean.toFixed(4)).toString() + '&nbsp;&nbsp;';
      let sdElement = document.getElementById('QCReview_Graph_SDValue_' + index);
      (sdElement as any).innerHTML = parseFloat(qcRef.sd.toFixed(4)).toString() + '&nbsp;&nbsp;';
      let cvElement = document.getElementById('QCReview_Graph_CVValue_' + index);
      (cvElement as any).innerHTML = parseFloat(qcRef.cv.toFixed(4)).toString() + '&nbsp;&nbsp;';
      let countElement = document.getElementById('QCReview_Graph_CountValue_' + index);
      (countElement as any).innerHTML = qcRef.count + '&nbsp;&nbsp;';
      chart.options = this.getUpdatedOptions(qcRef, data, upperVal, lowerVal);
      chart.update();
    }, 0);
  }

  getUpdatedOptions(qcRef: QcReference, item: QcLJGraphMetaData, upperVal: number, lowerVal: number) : any {
    let ymin = this.qcReviewService.getYMinValue(qcRef);
    let ymax = this.qcReviewService.getYMaxValue(qcRef);
    const [selectedFrom, selectedTo] = this.selectedFilter?.dateRangeString?.split('-') ?? [];
    const xminDate = moment(new Date(selectedFrom), 'YYYY-MM-DD', true).toDate();
    const xMaxDate = moment(new Date(selectedTo), 'YYYY-MM-DD', true).toDate();
    xMaxDate.setDate(xMaxDate.getDate() + 1);
    let xmin = xminDate.getTime();
    let xmax = xMaxDate.getTime();
    const scatterOptions: ChartConfiguration['options'] = {
      scales: {
        x: {
          title: {
            text: 'Completed Date',
            display: true,
            align: 'center',
            padding: 1,
            color: '000000E6',
            font: {
              size: 18,
              family: 'Siemens Sans',
              weight: 'bold',
            },
          },
          type: 'time',
          time: {
            unit: 'hour',
            displayFormats: {
              'hour': 'DD/MM/YYYY'
            }
          },
          // position: 'bottom',
          max: xmax,
          min: xmin,
          afterBuildTicks: scale => {
            scale.ticks = this.generateXAxisTicks(xminDate, xMaxDate);
          },
          ticks: {
            callback: (value, index, values) => {
              //tried to convert time to date time string. needs work as data is not showing for some reason
              return new Date(value).toLocaleDateString();
            },
            autoSkipPadding: 20,
          },
          adapters: {
            date: {
              zone: 'utc', // Ensure the time zone used is UTC
            }
          },
          bounds: 'ticks'
        },
        y: {
          title: {
            text: 'QC Result Value',
            display: true,
            align: 'center',
            padding: 1,
            color: '000000E6',
            font: {
              size: 18,
              family: 'Siemens Sans',
              weight: 'bold',
            },
          },
          afterBuildTicks: scale => {
            //used to define ticks which in turn define grid lines
            // ranging from +- 6sd to mean
            scale.ticks = this.generateYAxisTicks(qcRef, ymin, ymax);
          },
          max: ymax,
          min: ymin,
          type: 'linear',
          position: 'left',
          ticks: {
            //do not show number if tick value is greater than or lesser than 3sd. didnt work when directly specifying above
            callback: (value, index, values) => this.generateYAxisTicksLabels(value, qcRef, ymax, ymin)
          },
          grid: {
            display: false
          }
        },
      },
      plugins: {
        tooltip: {
          titleColor: 'black',
          backgroundColor: 'white',
          bodyColor: 'black',
          callbacks: {
            label: (tooltipItem) => {
              return (tooltipItem.raw as any)['tooltipLabel'].split('\n');
            },
            title: (tooltipItem) => {
              if (tooltipItem.length > 0) {
                let resVal = (tooltipItem[0].raw as any).y;
                if (resVal >= qcRef.sd2Low && resVal <= qcRef.sd2Up) return '1S';
                if (resVal >= qcRef.sd3Low && resVal <= qcRef.sd3Up) return '2S';
                return '3S';
              }
              return '';
            }
          }
        },
        legend: {
          display: true,
          position: 'bottom',
          align: 'end',
          title: {
            text: this.getLegendTitle(),
            display: true
          },
          labels: {
            usePointStyle: true,
            padding:15,
            generateLabels: (chart) => this.generateYAxisTickLabels(chart)
          }
        },
        annotation: {
          annotations: [                                        
            ...qcRef.sd === 0 ?
            [
              {
                type: 'box',
                xScaleID: 'x',
                yScaleID: 'y',
                yMin: qcRef.mean - 1,
                yMax: qcRef.mean + 1,
                backgroundColor: '#009B000A',
                borderWidth: 0,
                drawTime: 'beforeDatasetsDraw',
              },
              {
                type: 'line',
                scaleID: 'y',
                value: qcRef.mean.toString(),
                borderColor: '#009B00',
                borderWidth: 0.5,
                drawTime: 'afterDraw',
              },
            ] :
            [
              {
                type: 'box',
                xScaleID: 'x',
                yScaleID: 'y',
                yMin: ymin,
                yMax: qcRef.sd3Low,
                backgroundColor: '#E7001D33',
                borderWidth: 0,
                drawTime: 'beforeDatasetsDraw',
              },
              {
                type: 'box',
                xScaleID: 'x',
                yScaleID: 'y',
                yMin: qcRef.sd3Up,
                yMax: ymax,
                backgroundColor: '#E7001D33',
                borderWidth: 0,
                drawTime: 'beforeDatasetsDraw',
              },
              {
                type: 'box',
                xScaleID: 'x',
                yScaleID: 'y',
                yMin: qcRef.sd2Up,
                yMax: qcRef.sd3Up,
                backgroundColor: '#FCF50033',
                borderWidth: 0,
                drawTime: 'beforeDatasetsDraw',
              },
              {
                type: 'box',
                xScaleID: 'x',
                yScaleID: 'y',
                yMin: qcRef.sd3Low,
                yMax: qcRef.sd2Low,
                backgroundColor: '#FCF50033',
                borderWidth: 0,
                drawTime: 'beforeDatasetsDraw',
              },
              {
                type: 'box',
                xScaleID: 'x',
                yScaleID: 'y',
                yMin: qcRef.sd2Low,
                yMax: qcRef.sd2Up,
                backgroundColor: '#009B000A',
                borderWidth: 0,
                drawTime: 'beforeDatasetsDraw',
              },
              {
                type: 'line',
                scaleID: 'y',
                value: qcRef.mean,
                borderColor: '#009B00',
                borderWidth: 0.5,
                drawTime: 'beforeDatasetsDraw',
              },
              {
                type: 'line',
                scaleID: 'y',
                value: qcRef.sd1Low,
                borderColor: '#009B00',
                borderWidth: 0.5,
                drawTime: 'beforeDatasetsDraw',
              },
              {
                type: 'line',
                scaleID: 'y',
                value: qcRef.sd1Up,
                borderColor: '#009B00',
                borderWidth: 0.5,
                drawTime: 'beforeDatasetsDraw',
              },
              {
                type: 'line',
                scaleID: 'y',
                value: qcRef.sd2Low,
                borderColor: '#009B00',
                borderWidth: 0.5,
                drawTime: 'beforeDatasetsDraw',
              },
              {
                type: 'line',
                scaleID: 'y',
                value: qcRef.sd2Up,
                borderColor: '#009B00',
                borderWidth: 0.5,
                drawTime: 'beforeDatasetsDraw',
              },
              {
                type: 'line',
                scaleID: 'y',
                value: qcRef.sd3Up,
                borderColor: '#FCF500',
                borderWidth: 0.5,
                drawTime: 'beforeDatasetsDraw',
              },
              {
                type: 'line',
                scaleID: 'y',
                value: qcRef.sd3Low,
                borderColor: '#FCF500',
                borderWidth: 0.5,
                drawTime: 'beforeDatasetsDraw',
              },
              {
                type: 'line',
                scaleID: 'y',
                value: qcRef.sd3Up + qcRef.sd,
                borderColor: '#E7001D',
                borderWidth: 0.5,
                drawTime: 'beforeDatasetsDraw',
              },
              {
                type: 'line',
                scaleID: 'y',
                value: qcRef.sd3Up + qcRef.sd + qcRef.sd,
                borderColor: '#E7001D',
                borderWidth: 0.5,
                drawTime: 'beforeDatasetsDraw',
              },
              {
                type: 'line',
                scaleID: 'y',
                value: ymax,
                borderColor: '#E7001D',
                borderWidth: 0.5,
                drawTime: 'beforeDatasetsDraw',
              },
              {
                type: 'line',
                scaleID: 'y',
                value: qcRef.sd3Low - qcRef.sd,
                borderColor: '#E7001D',
                borderWidth: 0.5,
                drawTime: 'beforeDatasetsDraw',
              },
              {
                type: 'line',
                scaleID: 'y',
                value: qcRef.sd3Low - qcRef.sd - qcRef.sd,
                borderColor: '#E7001D',
                borderWidth: 0.5,
                drawTime: 'beforeDatasetsDraw',
              },
              {
                type: 'line',
                scaleID: 'y',
                value: ymin,
                borderColor: '#E7001D',
                borderWidth: 0.5,
                drawTime: 'beforeDatasetsDraw',
              },
            ],
            ...item.qcResults.filter((a : any) => a.errFlgs != '').map((val: any) => (
                ({
                  type: 'point',
                  backgroundColor: 'transparent',
                  borderColor: 'red',
                  pointStyle: 'rectRounded',
                  radius: 9,
                  xValue: (moment(val.time.split('Z')[0], true).toDate()),
                  yValue: val.value,
                  drawTime: 'afterDatasetsDraw',
                })
            ))          
          ] as any[]
        }
      },
      responsive: true,
      maintainAspectRatio: false,
    };
    return scatterOptions;
  }

  private getLegendTitle(): string | undefined {
    let legendTitle = '';
    if (this.selectedFilter.reagentLot === 'View All') {
      legendTitle = 'Reagent Lots';
    }
    else if (this.selectedFilter.calLot === 'View All') {
      legendTitle = 'Calibration Lots';
    }
    return legendTitle;
  }

  private generateYAxisTickLabels(chart: Chart) {
    let legendItems: LegendItem[] = [];
    let data = chart.data.datasets[0].data;
    let map = new Map<string, string>();
    for (let dataPoint of data) {
      if ((dataPoint as any).selected !== '') {
        map.set((dataPoint as any).selected, (dataPoint as any).color);
      }
    }
    legendItems.push({
      text: 'QC error flag  ',
      pointStyle: 'rectRounded',
      borderRadius: 10,
      fillStyle: 'transparent',
      strokeStyle: 'red',
    });
    for (let pair of map) {
      let item: LegendItem = {
        text: pair[0],
        fillStyle: pair[1]
      };
      legendItems.push(item);
    }
    return legendItems;
  }

  private generateYAxisTicksLabels(value: string | number, qcRef: QcReference, ymax: number, ymin: number) {
    let str = parseFloat((+value).toFixed(4)).toString();
    if (qcRef.sd === 0) {
      str = value === qcRef.mean ? 'Mean ' + str : '';
    }
    else {
      switch (value) {
        case ymax:
          str = '+6 SD  ' + str;
          break;
        case qcRef.sd3Up + (qcRef.sd * 2):
          str = '+5 SD  ' + str;
          break;
        case qcRef.sd3Up + qcRef.sd:
          str = '+4 SD  ' + str;
          break;
        case qcRef.sd3Up:
          str = '+3 SD  ' + str;
          break;
        case qcRef.sd2Up:
          str = '+2 SD  ' + str;
          break;
        case qcRef.sd1Up:
          str = '+1 SD  ' + str;
          break;
        case qcRef.mean:
          str = 'Mean  ' + str;
          break;
        case qcRef.sd1Low:
          str = '-1 SD  ' + str;
          break;
        case qcRef.sd2Low:
          str = '-2 SD  ' + str;
          break;
        case qcRef.sd3Low:
          str = '-3 SD  ' + str;
          break;
        case qcRef.sd3Low - qcRef.sd:
          str = '-4 SD  ' + str;
          break;
        case qcRef.sd3Low - (qcRef.sd * 2):
          str = '-5 SD  ' + str;
          break;
        case ymin:
          str = '-6 SD  ' + str;
          break;
        default:
          str = '';
          break;
      }
      //fixed value to 2 decimal places
    }
    return str;
  }

  private generateYAxisTicks(qcRef: QcReference, ymin: number, ymax: number) {
    let ticksValues;
    if (qcRef.sd === 0) {
      ticksValues = [
        {
          value: qcRef.mean,
          label: qcRef.mean.toString()
        },
        {
          value: ymin,
          label: ''
        },
        {
          value: ymax,
          label: ''
        },
      ];
    }
    else {
      ticksValues = [
        {
          value: ymax,
          label: '',
        },
        {
          value: qcRef.sd3Up + (qcRef.sd * 2),
          label: '',
        },
        {
          value: qcRef.sd3Up + qcRef.sd,
          label: '',
        },
        {
          value: qcRef.sd3Up,
          label: qcRef.sd3Up.toString(),
        },
        {
          value: qcRef.sd2Up,
          label: qcRef.sd2Up.toString(),
        },
        {
          value: qcRef.sd1Up,
          label: qcRef.sd1Up.toString(),
        },
        {
          value: qcRef.mean,
          label: qcRef.mean.toString(),
        },
        {
          value: qcRef.sd3Low,
          label: qcRef.sd3Low.toString(),
        },
        {
          value: qcRef.sd2Low,
          label: qcRef.sd2Low.toString(),
        },
        {
          value: qcRef.sd1Low,
          label: qcRef.sd1Low.toString(),
        },
        {
          value: qcRef.sd3Low - qcRef.sd,
          label: '',
        },
        {
          value: qcRef.sd3Low - (qcRef.sd * 2),
          label: '',
        },
        {
          value: ymin,
          label: '',
        },
      ];
    }
    return ticksValues;
  }

  private generateXAxisTicks(xminDate: Date, xMaxDate: Date) {
    let timeArray = [];
    const startDate = moment(xminDate, 'YYYY-MM-DD', true).toDate();
    while (startDate <= xMaxDate) {
      let tick = {
        value: startDate.getTime(),
        label: startDate.toLocaleDateString()
      };
      timeArray.push(tick);
      startDate.setDate(startDate.getDate() + 1);
    }
    return timeArray;
  }

  getQcRef(graphData: QcLJGraphMetaData, lowerVal: number, upperVal: number): QcReference {
    let resData = this.qcReviewService.getFilteredData(this.data!, graphData.filter);
    let data = resData[0].qcResults;
    let count = 0;
    let sum = 0;
    for (const element of data) {
      if (element.value >= lowerVal && element.value <= upperVal) {
        sum += element.value;
        count++;
      }
    }
    let qcRef;
    if (count === 0) { // no data points are valid acc. to filters
      qcRef = {
        mean: 0,
        sd: 0,
        sd1Up: 0,
        sd1Low: 0,
        sd2Low: 0,
        sd2Up: 0,
        sd3Low: 0,
        sd3Up: 0,
        cv: 0,
        count: 0
      }
    }
    else {
      let mean = sum / count;
      let sumOfSquaredDifferences = 0;
      for (const element of data) {
        if (element.value >= lowerVal && element.value <= upperVal) {
          sumOfSquaredDifferences += (element.value - mean) ** 2;
        }
      }
      //
      let variance = sumOfSquaredDifferences / count;
      let sd = parseFloat(Math.sqrt(variance).toFixed(4));
      mean = parseFloat(mean.toFixed(4));
      qcRef = {
        mean,
        sd,
        sd1Up: parseFloat((mean + sd).toFixed(4)),
        sd1Low: parseFloat((mean - sd).toFixed(4)),
        sd2Low: parseFloat((mean - (2 * sd)).toFixed(4)),
        sd2Up: parseFloat((mean + (2 * sd)).toFixed(4)),
        sd3Low: parseFloat((mean - (3 * sd)).toFixed(4)),
        sd3Up: parseFloat((mean + (3 * sd)).toFixed(4)),
        cv: parseFloat(((sd / mean) * 100).toFixed(4)),
        count: count
      }
    }
    this.assignQcRefValuesToLJGraphData(qcRef, graphData);
    return qcRef;
  }

  async resetFilter() {
    this.loc.resetToDefault();
    await this.setDefaultLJFilter();
  }

  onHeaderTooltipClick() {
    let url = 'https://cs-pi.siemens-healthineers.com/cb-doc/5Y_tCOeXZZN5yJMYMjAaDr_v3cdUAEUV92qgYxvkOtI/index.html#/documents/LDAT-011.840.29.XX.02.json?fragment=%252FLDAT-011.840.29.XX.02.748feb58703b98ee0a53dbda56b3167d.dd2ffbbd703b96c80a53dbda0f99d88d.html&slug=troubleshooting-ch-applications-troubleshooting-instructions-c02_c#7c402339703b7ccd0a53dbda0f99d88d.7c402339703b7ccd0a53dbda0f99d88d';
    window.open(url, '_blank');
  }

  onZoom(event: any, index: number) {
    let el = document.getElementById('QCReview_Graph_ChartContainerBody_' + index);
    let icon = event.target.icon;
    if (icon === 'zoom-plus') {
      icon = 'zoom-minus';
      let days = Math.floor((this.selectedFilter.latestDate.getTime() + (1000 * 60 * 60 * 24) - this.selectedFilter.earliestDate.getTime()) / (1000 * 60 * 60 * 24)); //1000 * 60 * 60 * 24 = milliseconds in a day
      (el as any).style.width = (180 * days).toString() + 'px';
    }
    else {
      icon = 'zoom-plus';
      (el as any).style.width = '100%';
    }
    event.target.setAttribute('icon', icon);
  }

  navigateToFleetComp(index: number) {
    let data = this.filteredData[index];
    let filter: QcLJSelectedFilter = {
      serial: data.filter.serial,
      test: data.filter.test,
      units: data.filter.units,
      qcLotLevel: data.filter.qcLotLevel,
      reagentLot: 'None',
      calLot: 'None',
      earliestDate: data.filter.earliestDate,
      latestDate: data.filter.latestDate,
      specimenType: data.filter.specimenType,
      dateRangeString: data.filter.dateRangeString,
      assay: data.filter.assay
    }
    let fleetFilteredData = this.qcReviewService.getFilteredData(this.data!, filter)[0];
    let qcLotHyphenIndex = data.qcLotLevel.lastIndexOf('-');
    let qcLotLevel = qcLotHyphenIndex == -1 ? data.qcLotLevel : data.qcLotLevel.slice(0, qcLotHyphenIndex);
    data.filter.reagentLot = data.filter.reagentLot === 'All' || data.filter.reagentLot === 'View All' ? 'None' : data.filter.reagentLot;
    data.filter.calLot = data.filter.calLot === 'All' || data.filter.calLot === 'View All' ? 'None' : data.filter.calLot;
    let fleetReqModel: FleetCompRequestModel = {
      AssayCode: data.assay,
      QcLotId: qcLotLevel,
      CalibrationLotId: ['All', 'None', 'View All'].includes(data.filter.calLot) ? '' : data.filter.calLot,
      ResultUnits: data.unit,
      ReagentLotId: ['All', 'None', 'View All'].includes(data.filter.reagentLot) ? '' : data.filter.reagentLot,
      FromDate: (new Date(new Date().getTime() - (180 * 24 * 60 * 60 * 1000))).toLocaleDateString(),
      ToDate: data.filter.latestDate.toLocaleDateString(),
      ModuleType: this.selectedModuleType()?.moduleType ?? ''
    }
    let slider = this.getSliderDetails(index);
    let miscData: MiscLJChartDataModel = {
      minRange: this.isSliderModified(index) ? fleetFilteredData.qcResultMin : slider?.lowerValue ?? Number.MIN_SAFE_INTEGER,
      maxRange: this.isSliderModified(index) ? fleetFilteredData.qcResultMax : slider?.upperValue ?? Number.MAX_SAFE_INTEGER,
    }
    this.updateFiltersInStore();
    this.fleetCompService.navigateToFleetComp(fleetReqModel, fleetFilteredData, miscData);
  }

  isSliderModified(index: number): boolean {
    const data = this.filteredData[index];
    const slider = this.getSliderDetails(index);
    return (data.qcResultMin === slider?.lowerValue && data.qcResultMax === slider?.upperValue);
  }

  getSliderDetails(index: number): any {
    return document.getElementById('QCReview_Graph_RangeSlider_' + index) as any;
  }

  navigateToQcComp(index: number) {
    let data = this.filteredData[index];
    let filter: QcLJSelectedFilter = {
      serial: data.filter.serial,
      test: data.filter.test,
      units: data.filter.units,
      qcLotLevel: data.filter.qcLotLevel,
      reagentLot: data.filter.reagentLot,
      calLot: data.filter.calLot,
      earliestDate: data.filter.earliestDate,
      latestDate: data.filter.latestDate,
      specimenType: data.filter.specimenType,
      dateRangeString: data.filter.dateRangeString,
      assay: data.filter.assay
    }
    let filteredData = this.qcReviewService.getFilteredData(this.data!, filter)[0];
    let slider = document.getElementById('QCReview_Graph_RangeSlider_' + index) as any;
    let miscData: MiscLJChartDataModel = {
      minRange: slider?.lowerValue ?? Number.MIN_SAFE_INTEGER,
      maxRange: slider?.upperValue ?? Number.MAX_SAFE_INTEGER,
    }
    let qcRef = this.getQcRef(filteredData, miscData.minRange, miscData.maxRange);
    this.updateFiltersInStore();
    this.qcComparisonService.navigateToQCComparison(filteredData, miscData, qcRef);
  }

  async applyDateRange(_event: any) {
    try {
      this.gettingNewData = true;
      const [selectedFrom, selectedTo] = this.defaultQcFilter?.selectedDateRangeString?.split('-') ?? [];
      const dateString = `${this.qcReviewService.convertToMMDDYYYY(selectedFrom)}-${this.qcReviewService.convertToMMDDYYYY(selectedTo)}`;
      this.data = await this.qcReviewService.getData(this.deviceId, dateString, this.defaultQcFilter.endOfCalendar);
      this.assayList = this.qcReviewService.getAssays(this.selectedFilter, this.data);
      this.selectedFilter.test = this.getAssayData(this.selectedFilter.assay);
      this.updateFilters('DefaultFilter');
    } catch (error) {
      console.log(error);
    }
    finally {
      this.gettingNewData = false;
    }
  }

  onDatePickerClick(event: any) {
    const el = document.getElementById('applyBtn');
    const value = event.target.__selectedDates;
    el?.setAttribute('disabled', 'disabled');
    if (value?.length !== 2) return;  
    const [startDate, endDate] = value;
    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) return;  

    const dayDiff = Math.round((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)) + 1;
    const isWithinRange = (dayDiff >= 7 && dayDiff <= 30);
    if(isWithinRange){
      el?.removeAttribute('disabled');
      this.showMessage = false;
    }
    else{
      el?.setAttribute('disabled', 'disabled');
      this.showMessage = true;
    }
    
    const isNotDisableDate = this.qcReviewService.getPreviousOneMonthDate(endDate) >= this.qcReviewService.convertToMMDDYYYY(event.target.__minDate);
    this.noteMessage = (!isNotDisableDate && dayDiff <= 30)
      ? `${this.qcReviewService.convertToMMDDYYYY(startDate)} - ${this.qcReviewService.convertToMMDDYYYY(endDate)}`
      : `${this.qcReviewService.getPreviousOneMonthDate(endDate)} - ${this.qcReviewService.convertToMMDDYYYY(endDate)}`;
    this.defaultQcFilter.endOfCalendar = (!isNotDisableDate && dayDiff <= 30);
    this.defaultQcFilter.selectedDateRangeString = `${this.qcReviewService.convertToMMDDYYYY(startDate)}-${this.qcReviewService.convertToMMDDYYYY(endDate)}`;
  }
}
