import { Injectable } from '@angular/core';
import { QcDataStoreService } from './qc-data-store.service';
import { QcService } from './qc.service';
import { NetworkService } from './communication/network.service';
import { SseService } from './communication/sse.service';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root',
})

export class QcDashboardService extends QcService {
  constructor(qcDataStore: QcDataStoreService, appConfigService: AppConfigService, sseService: SseService, networkService: NetworkService) {
    super(appConfigService, sseService, networkService, qcDataStore);
  }

  override async getData(deviceId: string, dateRange?: string, lastDay?: boolean) {
    let data = await super.getData(deviceId, dateRange, lastDay);
    this.qcDataStore.updateData(data);
    return data
  }

}
