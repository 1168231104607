import { createSelector } from '@ngrx/store';
import { globalStateFeature } from './global.reducers';

export const selectReleaseFeatureFlags = createSelector(
  globalStateFeature.selectReleaseFeatureFlags,
  (releaseFeatureFlags) => releaseFeatureFlags
);

export const selectReleaseToggleForDrop2 = createSelector(
  globalStateFeature.selectReleaseFeatureFlags,
  (releaseFeatureFlags) => releaseFeatureFlags.ReleaseToggleForDrop2
);

export const selectReleaseToggleForDrop3 = createSelector(
  globalStateFeature.selectReleaseFeatureFlags,
  (releaseFeatureFlags) => releaseFeatureFlags.ReleaseToggleForDrop3
);

export const selectReleaseToggleForMajorRelease = createSelector(
  globalStateFeature.selectReleaseFeatureFlags,
  (releaseFeatureFlags) => releaseFeatureFlags.ReleaseToggleForMajorRelease
);
