import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatFormField} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatOption, MatSelect} from "@angular/material/select";
import { NavBreadcrumbsComponent } from './components/nav-breadcrumbs/nav-breadcrumbs.component';
import { RouterModule } from '@angular/router';
import { MinMaxButtonComponent } from './components/min-max-button/min-max-button.component';
import { HeaderActionsComponent } from './components/header-actions/header-actions.component';
import { LocationSelectorComponent } from 'src/app/components/location-selector/location-selector.component';
import { CbDocButtonComponent } from './components/cb-doc-button/cb-doc-button.component';
import { ConfirmationPopupComponent } from './components/confirmation-popup/confirmation-popup.component';

@NgModule({
  declarations: [
    NavBreadcrumbsComponent,
    MinMaxButtonComponent,
    HeaderActionsComponent,
    LocationSelectorComponent,
    CbDocButtonComponent,
    ConfirmationPopupComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MatFormField,
    MatSelect,
    MatOption,
    FormsModule,
    MatInput
  ],
  exports: [
    NavBreadcrumbsComponent,
    MinMaxButtonComponent,
    HeaderActionsComponent,
    LocationSelectorComponent,
    CbDocButtonComponent,
    ConfirmationPopupComponent
  ],
  providers: [],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class SharedModule {}
