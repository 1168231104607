import { Injectable } from '@angular/core';
import { AnnotationConfig } from './model/annotation.model';

@Injectable({
  providedIn: 'root'
})

export class AnnotationService {

  annotations: any[] = [];

  deviationColors: Record<string, { backgroundColor: string; borderColor: string }> = {
    mean: { backgroundColor: '#009B000A', borderColor: '' },
    sD1Low: { backgroundColor: '#009B000A', borderColor: '#009B00' },
    sD1Up: { backgroundColor: '#009B000A', borderColor: '#009B00' },
    sD3Low: { backgroundColor: '#E7001D33', borderColor: '#FCF500' },
    sD3Up: { backgroundColor: '#E7001D33', borderColor: '#FCF500' },
    sD2Low: { backgroundColor: '#FCF50033', borderColor: '#009B000A' },
    sD2Up: { backgroundColor: '#FCF50033', borderColor: '#009B000A' },
    // sD3Line: { backgroundColor: '#E7001D', borderColor: '#E7001D' },
  };

  generateAnnotations(dataPoint: any, annotationconfigs: AnnotationConfig[]): AnnotationConfig[] {
    const annotations: AnnotationConfig[] = [];

    annotationconfigs.forEach((config: AnnotationConfig) => {
      const deviationColor = this.deviationColors[config.name] || {};
      const annotation: AnnotationConfig = {
        name: config.name,
        type: config.type,
        // scaleid: config.scaleID,
        borderWidth: config.borderWidth ?? 0.5,
        // borderColor: config.borderColor || deviationColor.borderColor || '#00000000',
        // label: config.label || {},
        drawTime: config.drawTime ?? 'beforeDatasetsDraw'
      };

      // set specific properties based on annotation type
      if (config.type === 'box') {
        annotation.xScaleID = 'x';
        annotation.yScaleID = 'y';
        annotation.yMin = config.yMin;
        annotation.yMax = config.yMax;
        annotation.backgroundColor = deviationColor.backgroundColor || '#00000000';// default to transparent
        annotation.borderColor = deviationColor.borderColor || '#00000000';
      }
      else if (config.type === 'line') {
        annotation.value = config.value;
        annotation.scaleID = 'y'
        annotation.borderDash = config.borderDash ?? undefined;
        // annotation.backgroundColor = config.backgroundColor || deviationColor.backgroundColor || '#00000000'
        annotation.borderColor = config.borderColor ?? deviationColor.borderColor ?? '#00000000'

        annotation.label = {
          content: config.label?.content ?? '',
          position: config.label?.position ?? 'end',
          usePointStyle: true,
          display: true,
          font: {
            family: 'Siemens Sans',
            size: 14,
            weight: 'normal'
          },
          // backgroundColor: config.backgroundColor,
          backgroundColor: config.label?.backgroundColor ?? 'rgba(255, 255, 255, 0.4)', //transparent
          padding: {
            bottom: 20
          },
          borderColor: 'rgba(255, 255, 255, 0.4)',//transparent
          color: config.label?.color ?? 'black'
        };
      }
      else if (config.type === 'point') {
        annotation.backgroundColor = config.backgroundColor;    
        annotation.borderColor = config.borderColor;
        annotation.pointStyle = config.pointStyle;
        annotation.radius = config.radius;
        annotation.xValue = config.xValue;
        annotation.yValue = config.yValue;
        annotation.drawTime = config.drawTime;
      }
      if(annotation.name === 'customerRangeLow'){
        // annotation.enter
      }
      annotations.push(annotation)
    });

    return annotations;
  }
}
