<sh-wrapper spacing="s">
  <sh-text id="Selector_SearchBy_Txt" size="body-1" class="search-by-text">Search By:&nbsp;&nbsp;&nbsp;&nbsp;
  </sh-text>
  <sh-radio-group id="Selector_SearchOption_RadioGrp" horizontal>
    <sh-radio-button id="Selector_SerialNo_RadioBtn" label='Serial No.' (click)="onSearchByLocation(false)" active>
    </sh-radio-button>
    <sh-radio-button id="Selector_Location_RadioBtn" label='Location' (click)="onSearchByLocation(true)">
    </sh-radio-button>
  </sh-radio-group>

  <!-- searching by serial no/device id start -->
  <sh-search id="Selector_SerialNo_Search"
             *ngIf="!this.searchByLocation"
             (clearsearch)="this.setLocationDetails($event)"
             whole-string
             (search)="setLocationDetails($event)"
             (value-changed)="setLocationDetails($event)"
             [error]="!this.allDeviceIdsList.includes(this.selectedDeviceId)"
             label="Search Serial No.">
    <sh-empty-state id="Selector_SerialNo_Empty"
                    slot='empty'
                    icon='laboratory-system'
                    label='No Device found.'>
    </sh-empty-state>
  </sh-search>
  <sh-input-text id="Selector_Country_Input"
                 label="Country"
                 readonly
                 *ngIf="!this.searchByLocation">
  </sh-input-text>
  <sh-input-text id="Selector_City_Input"
                 label="City"
                 readonly
                 *ngIf="!this.searchByLocation">
  </sh-input-text>
  <sh-input-text id="Selector_Customer_Input"
                 label="Customer"
                 readonly
                 *ngIf="!this.searchByLocation">
  </sh-input-text>
  <!-- searching by serial no/device id end -->

  <!-- searching by location start -->
  <!-- Country Search start -->
  <sh-dropdown id="Selector_Country_DropDown"
               label="Country"
               *ngIf="this.searchByLocation"
               (focusout)="onCountryFocusOut($event)"
               mandatory
               no-clear
               [editable] = "this.editableState"
               [value]=""
               (clear-clicked) = "this.resetCountrySelection()"
               [error]="this.selectedCountryIndex === -1"
               (click)="this.focusInput()"
               error-message="Country cannot be empty">
    <input matInput #countrySearchInputControl
           [formControl]="countrySearchControl" (focusin)="this.inputFocusIn()"
           placeholder="Search Country" type="text"
           class="filterBox" >

    <sh-menu-item  [id]="'Selector_' + countryIndex + 'Country_Menu'"
                  *ngFor="let country of this.searchCountryList; index as countryIndex"
                  [label]="country"
                  [active]="this.selectedCountryIndex === countryIndex"
                  (click)="this.onCountryChanged(country)">
    </sh-menu-item>
  </sh-dropdown>
  <!-- Country Search end -->

  <!-- City Search start -->
  <sh-dropdown id="Selector_City_DropDown"
               label="City"
               *ngIf="searchByLocation"
               multi-select
               mandatory
               [editable] = "this.editableState"
               no-clear
               (click)="this.focusInput()"
               [value]=""
               [error]="this.selectedCityIndices.size === 0"
               error-message="City cannot be empty"
               (clear-clicked) = "this.resetSearchByLocFields('onCountryChanged')">
    <input matInput #citySearchInputControl
           [formControl]="citySearchControl" (focusin)="this.inputFocusIn()"
           placeholder="Search City"
           type="text"
           class="filterBox" >
    <sh-menu-item [id]="'Selector_' + cityIndex + 'City_Menu'"
                  *ngFor="let city of this.searchedCityList; index as cityIndex"
                  [label]="city"
                  [active]="this.selectedCityIndices.has(this.cityList.indexOf(city))"
                  (click)="this.onCityChanged($event, cityIndex,city)">
    </sh-menu-item>
  </sh-dropdown>
  <!-- City Search end -->

  <!-- Customer Search start -->
  <sh-dropdown id="Selector_Customer_DropDown"
               label="Customer"
               (focusout)="onCustomerFocusOut($event)"
               *ngIf="this.searchByLocation"
               mandatory
               [editable] = "this.editableState"
               no-clear
               (click)="focusInput()"
               [error]="this.selectedCustomer === ''"
               error-message="Customer cannot be empty"
               (clear-clicked) = "this.resetSearchByLocFields('onCityChanged')">
    <input matInput #customerInputSearchControl
           [formControl]="customerSearchControl" (focusin)="this.inputFocusIn()"
           placeholder="Search Customer" type="text"
           class="filterBox" >
    <sh-menu-item [id]="'Selector_' + customerIndex + 'Customer_Menu'"
                  *ngFor="let customer of searchCustomerList; index as customerIndex"
                  [label]="customer"
                  (click)="this.onCustomerChanged(customer)"
                  [active]="customer == this.selectedCustomer">
    </sh-menu-item>
  </sh-dropdown>
  <!-- Customer Search end -->

  <!-- DeviceId Search start -->
  <sh-dropdown id="Selector_SerialNo_DropDown"
               label="Serial No." mandatory
               *ngIf="this.searchByLocation"
               editable
               (focusout)="onDeviceIdFocusOut($event)"
               no-clear [error]="this.selectedDeviceId === ''"
               (clear-clicked) = "this.resetDeviceIdSelection()"
               error-message="Serial Number cannot be empty"
               (value-changed) = "serialValueEvent($event)"
               (clear-clicked) = "this.resetSearchByLocFields('onCustomerChanged')">
    <sh-menu-item [id]="'Selector_' + deviceIndex + 'Device_Menu'"
                  *ngFor="let deviceId of deviceIdList;index as deviceIndex"
                  [label]="deviceId"
                  (click)="this.onSerialNoChanged(deviceId)"
                  [active]="deviceId == this.selectedDeviceId">
    </sh-menu-item>
  </sh-dropdown>
  <!-- DeviceId Search end -->
  <!-- searching by location end -->
</sh-wrapper>
