import { Injectable } from '@angular/core';
import { AppConfigService } from './app-config.service';
import { SseService } from './communication/sse.service';
import { NetworkService } from './communication/network.service';
import { filter, firstValueFrom, timeout } from 'rxjs';
import { DataType } from '../enums/data-type.enum';
import { QcCommunicationModel } from '../model/qc.communication.model';
import { QcQuestionnaireCommunicationModel } from '../model/questionnaire.model';
import { Constants } from '../constants/constants';
import { QcDataStoreService } from './qc-data-store.service';
import { NotificationResponseModel } from '../model/notification.model';
import { QCDateFilter, QcFilter } from '../model/qc-dashboard.model';
import { AssayTroubleshootingModule } from '../modules/troubleshooting/consts/assay-troubleshooting-module.enum';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class QcService {

  defaultQcCommunicationModel: QcCommunicationModel = {
    dataType: 3,
    data: {
      dataType: '3',
      requestId: "",
      qcDetails: {
        qcDataList: [],
        serialNo: ""
      }
    }
  };


  constructor(protected appConfigService: AppConfigService, protected sseService: SseService, protected networkService: NetworkService, protected qcDataStore: QcDataStoreService) { }

  async getData(deviceId: string, dateRange: string = "", lastDay: boolean = false): Promise<QcCommunicationModel> {
    try {
      const [earliestDate, latestDate] = (dateRange || '').split('-');
      const isDefaultDate = this.getDateDifference(dateRange);
      const fromDate = (lastDay || isDefaultDate) ? moment(earliestDate, 'MM/DD/YYYY', true).toDate() 
      : moment(latestDate).subtract(29, 'day').toDate();
      const toDate = moment((latestDate), 'MM/DD/YYYY', true).toDate();       
     
      const fromDateString = moment(fromDate).format('YYYY-MM-DD');
      const toDateString = moment(toDate).format('YYYY-MM-DD 23:59:59');

      let storeData = await this.getStoreData(deviceId, fromDate, toDate, isDefaultDate);
      if (storeData && !lastDay) return storeData;     
      
      let requestData =
      {
        "dataType": 3,
        "ClientId": this.appConfigService?.appConfig?.clientId,
        "data": {
          "ModuleType": "Atellica CH 930 Analyzer",
          "SerialNo": deviceId,
          "FromDate": fromDateString,
          "ToDate": toDateString
        }
      }
      let response: any = await this.networkService.post(requestData);
      if (response?.requestId) {
        let requestId = response.requestId;
        let data = await firstValueFrom(
          this.sseService.sseEvent$.pipe(
            filter(data => data != undefined && data.dataType === DataType.QCData && data.data.requestId == requestId),
            timeout(Constants.API_TIMEOUT)  // Unsubscribe if no value is emitted within 60 seconds
          ));
        if (data) {
          this.addQcLevelToLot(data);
          this.qcDataStore.updateData(data);
          return data;
        }
      }
    }
    catch (error) {
      console.error(error);
    }
    return this.defaultQcCommunicationModel;
  }

  async getQCQuestionnaire(selectedNotification: NotificationResponseModel | undefined, selectAssayTroubleShootingModuleType: AssayTroubleshootingModule): Promise<QcQuestionnaireCommunicationModel> {

    const defaultQuestionnaireCommunicationModel: QcQuestionnaireCommunicationModel = {
      dataType: DataType.QuestionnaireData,
      data: {
        notificationNo: selectedNotification?.notificationNo ?? "",
        moduleType: 'Atellica CH 930 Analyzer',
        questionnaireType: selectAssayTroubleShootingModuleType.keyId,
        questionnaireCategory: {
          questionnaireSections: [],
          type: '1'
        }
      }
    };

    let requestData =
    {
      "dataType": DataType.QuestionnaireData,
      "data": {
        "ModuleType": "Atellica CH 930 Analyzer",
        "QuestionnaireType": selectAssayTroubleShootingModuleType.keyId, //move to Enum,
        "NotificationNo": selectedNotification?.notificationNo ?? "",
        "SerialNo": selectedNotification?.serialNo ?? ""
      }
    }

    let response: any = await this.networkService.postWithoutSSE(requestData);
    if (response) return response;
    return defaultQuestionnaireCommunicationModel
  }

  async getStoreData(deviceId: string, startRange: Date, endRange: Date, isDefaultDate: boolean): Promise<QcCommunicationModel | undefined> {
    const map = await firstValueFrom(this.qcDataStore.qcData$);
    if (!map?.has(deviceId)) return undefined;

    const data = map.get(deviceId);
    const qcDataList = data?.data.qcDetails?.qcDataList;
    if (!qcDataList || qcDataList.length === 0) return undefined;

    // Check if any QC result is within the date range
    for (const assay of qcDataList) {
        if (!assay) continue;

        const qcResults = assay.qcResults;
        if (!qcResults || qcResults.length === 0) continue;
        const qcDateArray: any = qcResults.map(qcDate => qcDate.time?.split('T')[0]);
        // Convert strings to Date objects and find min and max
        const minDate =  new Date(Math.min(...qcDateArray.map((date: string|number|Date) => new Date(date))));
        const maxDate = new Date(Math.max(...qcDateArray.map((date: string|number|Date) => new Date(date))));
        if(isDefaultDate) {
          maxDate.setUTCDate(maxDate.getUTCDate() + 1); //Fetch storeData when isDefaultDate is ture)
        }
        //Return store data when selectedDate range within store min & max date
        const isSelectedDateInRange = this.isSelectedDateInRange(this.normalizeDate(minDate), this.normalizeDate(maxDate), this.normalizeDate(startRange), this.normalizeDate(endRange));
        if(isSelectedDateInRange) {
          return data; 
        }
    }
    return undefined;
  }

  // Function to check if a date is within the range
  isSelectedDateInRange(minDate: Date, maxDate: Date, start: Date, end: Date): boolean {
    return (start >= minDate && end <= maxDate);
  };

  normalizeDate(date: Date): Date {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0); // Set time to 00:00:00
    return newDate;
  }

  addQcLevelToLot(data: QcCommunicationModel) {
    if (!data || data?.data?.qcDetails.qcDataList.length === 0)
      return;
    for (let item of data.data.qcDetails.qcDataList) {
      item.qcLotLevel = item.qcLotLevel + '-' + item.qcLevel;
    }
  }

  getDefaultDateRange(): QCDateFilter {
    const MS_PER_DAY = 24 * 60 * 60 * 1000;
    const DAYS_IN_SIX_MONTH = 180; // dates enabled by default to 180 days
    const DAYS_IN_MONTH = 30; // dates default selection


    const today = new Date();
    const latestDate = new Date(today.setUTCDate(today.getUTCDate() + 1));
    const earliestSixDate = new Date(latestDate.getTime() - (DAYS_IN_SIX_MONTH * MS_PER_DAY));
    const earliestMonthDate = new Date(latestDate.getTime() - (DAYS_IN_MONTH * MS_PER_DAY));

    const formatDateString = (date: Date) => date.toISOString().split('T')[0];
    const latestDateString = formatDateString(latestDate);
    const earliestDateString = formatDateString(earliestSixDate);
    const earliestMonthDateString = formatDateString(earliestMonthDate);

    return {
      endOfCalendar:false,
      latestDateString,
      earliestDateString,
      defaultDateRangeString: `${this.convertToMMDDYYYY(earliestMonthDateString)}-${this.convertToMMDDYYYY(latestDateString)}`,
      selectedDateRangeString: `${this.convertToMMDDYYYY(earliestMonthDateString)}-${this.convertToMMDDYYYY(latestDateString)}`
    };
  }

  convertToMMDDYYYY(dateString: string): string {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  }

  getPreviousOneMonthDate(dateString: string): string {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }
    const pastDate = new Date(date);
    pastDate.setDate(date.getDate() - 29);
    const month = String(pastDate.getMonth() + 1).padStart(2, '0');
    const day = String(pastDate.getDate()).padStart(2, '0');
    const year = pastDate.getFullYear();
    return `${month}/${day}/${year}`;
  }


  getQCRouteParam(route: ActivatedRoute) {
    route.queryParams.subscribe((queryParams) => {
      if (!queryParams['notificationNo'] || !queryParams['serialNo']) {
        return;
      };
      const decoded = decodeURIComponent(queryParams['notificationNo']);
      const notification = JSON.parse(decoded);

      let selectedFilters: QcFilter = new QcFilter()

      selectedFilters.assay = notification.assayName ?? null;
      selectedFilters.deviceId = queryParams['serialNo'];

      this.qcDataStore.updateFilter(selectedFilters);
    })
  }

  getDateDifference(dateRange: string): boolean {
    const [startDate, endDate] = (dateRange || '').split('-').map(date => moment(date, "MM/DD/YYYY"));
    const diff = endDate.diff(startDate, 'days');
    return diff === 30;
  };
}
