import { ReleaseFeatureFlags } from '../model/release-feature-flags';

export interface GlobalState {
  releaseFeatureFlags: ReleaseFeatureFlags;
}

export const initialGlobalState: GlobalState = {
  releaseFeatureFlags: {
    ReleaseToggleForDrop2: true,
    ReleaseToggleForDrop3: false,
    ReleaseToggleForMajorRelease: false,
  },
};
