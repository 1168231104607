import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { GlobaStateDataActions } from './global.actions';
import { catchError, from, of, switchMap } from 'rxjs';
import {
  ReleaseFeatureFlagRequest,
  ReleaseFeatureFlagResponse,
} from '../model/release-feature-flags';
import { NetworkService } from '../services/communication/network.service';
import { DataType } from '../enums/data-type.enum';
import { FeatureFlag } from '../enums/feature-flag.enum';

@Injectable()
export class GlobalStateEffects {
  constructor(
    private actions$: Actions,
    private networkService: NetworkService
  ) {}

  loadReleaseFeatureFlags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobaStateDataActions.fetchFeatureFlags),
      switchMap(() => {
        const featureFlag: ReleaseFeatureFlagRequest = {
          dataType: DataType.FeatureFlag,
          data: {
            flag: FeatureFlag.all,
          },
        };
        return from(this.networkService.postWithoutSSE(featureFlag)).pipe(
          switchMap((releaseFlagResponse: ReleaseFeatureFlagResponse) => {
            return of(
              GlobaStateDataActions.fetchFeatureFlagSuccess({
                releaseFlagResponse: releaseFlagResponse.data.flags,
              })
            );
          })
        );
      }),
      catchError((error: Error) => {
        return of(
          GlobaStateDataActions.fetchFeatureFlagFailure({
            errorMsg: error.message,
          })
        );
      })
    )
  );
}
