import {Component, Input} from "@angular/core";

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrl: './feedback.component.scss'
})
export class FeedbackComponent {
  @Input() feedBackVisible = false;
  feedbackUrl: string = 'https://forms.office.com/e/QsYwx5t9QG';
  
  onSubmitFeedbackClick() {
    window.open(this.feedbackUrl, '_blank', 'noopener,noreferrer');
  }
  
  onEmailFeedbackClick() {
    const recipient = 'atasupport.func@siemens-healthineers.com';
    const subject = 'ATA Support Request';
    const body = `Hello Team,\n\n<<Please type your feedback/queries>>\n\nRegards,\nYour Name`;
    const mailtoUrl = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    this.setLocationHref(mailtoUrl, recipient, subject, body);
  }

  setLocationHref(mailtoUrl: string, recipient: string, subject: string, body: string) {
    const webUrl = `https://outlook.office.com/mail/deeplink/compose?to=${encodeURIComponent(recipient)}&subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
    const mailtoWindow = window.open(mailtoUrl);
    setTimeout(() => {
      if (!mailtoWindow || mailtoWindow.closed) {
        window.open(webUrl, '_blank');
      }
    }, 1000);
  }
}
