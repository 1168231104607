<div *ngIf="feedBackVisible" id="Feedback-Video" class="Feedback-modal-container">
  <div class="home-tutorial-container" visible="false">
    <mat-card class="home-tutorial-card">
      <div class="title-container">
        <h4 class="home-tutorial-title">Share Feedback</h4>
      </div>
      <div class="link-container">
        <div id="feedback-link-Email" class="feedback-link-container" (click)="onEmailFeedbackClick()">
          <sh-icon id="Feedback-email-icon" icon='envelope' button slot='functions'></sh-icon>
          <h3  id="Feedback-email-text" target="_blank" button rel="noopener" class="feedback-link">Email Us</h3>
        </div>
        <div id="feedback-link-Form" class="feedback-link-container" (click)="onSubmitFeedbackClick()">
          <sh-icon id="Feedback-form-icon" icon='page' button slot='functions'></sh-icon>
          <span id="Feedback-form-text" target="_blank" button rel="noopener" class="feedback-link">Submit Via Form</span>
        </div>
      </div>
    </mat-card>
  </div>
</div>